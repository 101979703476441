import React, { useCallback, useEffect, useState } from 'react'
import {
  View,
  TouchableOpacity,
  Image,
  Text,
  StyleSheet,
  Dimensions,
  ActivityIndicator,
  ScrollView,
  Modal,
} from 'react-native'
import { useDispatch } from 'react-redux'
import Geolocation from '@react-native-community/geolocation'
import dayjs from 'dayjs'

import { useTheme } from '@/Hooks'
import { BorderRadius, Colors, Height } from '@/Theme/Variables'
import {
  FadeInView,
  Header,
  LoadingComponent,
  WarningSignComponent,
} from '@/Components'
import { navigate, setPendingRedirect } from '@/Navigators/utils'
import { PagesEnum } from '@/Navigators/Application'
import loyaltyProgramsApi from '@/Services/modules/loyaltyPrograms'
import { globalActions } from '@/Store/Global'
import { AuthPagesEnum } from '@/Navigators/withAuthPages'
import { capitalizedSentence, removeEmptyKeys } from '@/Util/global'
import { advertiseActions } from '@/Store/Advertise'

const CouponDetailContainer = ({ navigation, route }: any) => {
  const { Layout, Images, Fonts, Gutters } = useTheme()
  const styles = getStyle()
  const dispatch = useDispatch()
  const { id } = route.params

  const { data: couponDetail, isLoading } =
    loyaltyProgramsApi.useGetCouponDetailQuery({ id })

  const [
    redeemCouponRequest,
    {
      data: redeemCouponData,
      isLoading: isRedeeming,
      isSuccess: isRedeemSuccess,
      isError: isRedeemError,
      reset: resetRedeem,
    },
  ] = loyaltyProgramsApi.useRedeemCouponMutation({
    fixedCacheKey: 'shared-redeem-coupon',
  })

  const [submitLoading, setSubmitLoading] = useState(false)
  const [modalVisible, setModalVisible] = useState(false)
  const [getLocationErrorModalVisible, setGetLocationErrorModalVisible] =
    useState(false)
  const [location, setLocation] = useState<{
    latitude: number
    longitude: number
  } | null>(null)
  const [getLocationLoading, setGetLocationLoading] = useState(false)
  const [getLocationSuccess, setGetLocationSuccess] = useState(false)

  useEffect(() => {
    // 如果 displayFormat 不是 barcode 或 qrcode，則直接跳轉到結果頁面
    const displayFormat = redeemCouponData?.data.vendorData.displayFormat
    const toRedeemPage =
      isRedeemSuccess &&
      (displayFormat === 'barcode' || displayFormat === 'qrcode')

    if (toRedeemPage) {
      resetRedeem()
      navigation.navigate(AuthPagesEnum.CouponRedeemContainer, {
        id,
        couponName: couponDetail?.data.program.couponName,
      })
      return
    }

    if (displayFormat == null && isRedeemSuccess) {
      resetRedeem()
      navigation.navigate(AuthPagesEnum.CouponResultContainer, {
        type: 'success',
        couponName: couponDetail?.data.program.couponName,
      })
    }
  }, [
    isRedeemSuccess,
    resetRedeem,
    id,
    navigation,
    couponDetail,
    redeemCouponData?.data.vendorData.displayFormat,
  ])

  useEffect(() => {
    if (isRedeemSuccess) {
      setGetLocationSuccess(false)
      setGetLocationLoading(false)
      setLocation(null)
    }
  }, [isRedeemSuccess])

  useEffect(() => {
    if (isRedeemError) {
      navigation.navigate(AuthPagesEnum.CouponResultContainer, {
        type: 'failed',
      })
      resetRedeem()
    }
  }, [isRedeemError, navigation, resetRedeem])

  const handleGoBack = useCallback(() => {
    if (navigation.canGoBack()) {
      navigation.goBack()
      return
    }

    navigate(PagesEnum.Main, {
      screen: 'Profile',
    })
  }, [navigation])

  const onClose = useCallback(() => {
    setModalVisible(false)
    setGetLocationErrorModalVisible(false)
  }, [])

  const getGeolocation = useCallback(() => {
    return new Promise<{ latitude: number; longitude: number } | null>(
      resolve => {
        Geolocation.getCurrentPosition(
          position => {
            resolve({
              latitude: position.coords.latitude,
              longitude: position.coords.longitude,
            })
            setGetLocationSuccess(true)
          },
          () => {
            resolve(null)
            setGetLocationLoading(false)
            setGetLocationSuccess(false)
            setGetLocationErrorModalVisible(true)
            setModalVisible(false)
          },
          {
            enableHighAccuracy: true,
            timeout: 5000,
            maximumAge: 0,
          },
        )
      },
    )
  }, [])

  const getGeolocationData = useCallback(async () => {
    setGetLocationLoading(true)
    const result = await getGeolocation()
    setLocation(result)
    setGetLocationLoading(false)
    setModalVisible(false)
    return result
  }, [getGeolocation])

  const submitCouponWithLocation = useCallback(async () => {
    if (!location?.latitude || !location?.longitude) {
      setGetLocationErrorModalVisible(true)
      return
    }
    try {
      if (couponDetail && location?.latitude && location?.longitude) {
        dispatch(globalActions.closeBottomDialog())
        const payload = {
          id,
          programId: couponDetail.data.program.id,
          metadata: {
            latitude: location?.latitude,
            longitude: location?.longitude,
          },
        }
        redeemCouponRequest(removeEmptyKeys(payload))
      }
    } catch (error) {
      console.error(error)
    } finally {
      setSubmitLoading(false)
      setModalVisible(false)
    }
  }, [
    couponDetail,
    dispatch,
    id,
    redeemCouponRequest,
    setModalVisible,
    setSubmitLoading,
    location,
  ])

  const renderCouponDetail = (title: string, content: string) => {
    return (
      <View style={[Gutters.smallBMargin]}>
        <Text
          style={[
            Fonts.size16,
            Fonts.weight700,
            Gutters.miniBMargin,
            { color: Colors.fontText.light.primary2 },
          ]}
        >
          {title}
        </Text>
        <Text
          style={[
            Fonts.size14,
            Fonts.weight400,
            { color: Colors.fontText.light.primary3 },
          ]}
        >
          {content}
        </Text>
      </View>
    )
  }

  const renderCouponOtherSpirits = (
    title: string,
    spirits: { id: string; title: string }[],
  ) => {
    return (
      <View style={[Gutters.smallBMargin]}>
        <Text
          style={[
            Fonts.size16,
            Fonts.weight700,
            Gutters.miniBMargin,
            { color: Colors.fontText.light.primary2 },
          ]}
        >
          {title}
        </Text>
        {spirits.map(spirit => {
          return (
            <TouchableOpacity
              onPress={() => {
                setPendingRedirect(AuthPagesEnum.CouponDetailContainer, {
                  id: id,
                })
                navigate(PagesEnum.ProductDetail, {
                  id: spirit.id,
                })
              }}
            >
              <Text
                style={[
                  Fonts.size14,
                  Fonts.weight400,
                  { color: Colors.primary },
                ]}
              >
                {capitalizedSentence(spirit.title)}
              </Text>
            </TouchableOpacity>
          )
        })}
      </View>
    )
  }

  const renderCampaignLink = () => {
    if (!couponDetail?.data.program.campaignLink) {
      return null
    }
    return (
      <View style={[Gutters.smallBMargin]}>
        <Text
          style={[
            Fonts.size16,
            Fonts.weight700,
            Gutters.miniBMargin,
            { color: Colors.fontText.light.primary2 },
          ]}
        >
          活動資訊
        </Text>
        <TouchableOpacity
          onPress={() => {
            dispatch(
              advertiseActions.setCampaignSiteAdvertiseURL(
                couponDetail?.data.program.campaignLink,
              ),
            )
            navigate(PagesEnum.CampaignSite, {})
          }}
        >
          <Text
            style={[Fonts.size14, Fonts.weight400, { color: Colors.primary }]}
          >
            點此查看活動內容
          </Text>
        </TouchableOpacity>
      </View>
    )
  }

  const renderAlertModal = () => {
    return (
      <Modal
        visible={modalVisible}
        transparent
        animationType="fade"
        onRequestClose={onClose}
      >
        <View style={styles.overlay}>
          <View
            style={[
              styles.container,
              {
                width: Dimensions.get('window').width * 0.9,
                height: Dimensions.get('window').height * 0.8,
              },
            ]}
          >
            {getLocationLoading ? (
              <View style={[Layout.center, Layout.fill]}>
                <ActivityIndicator size={'large'} color={Colors.primary} />
              </View>
            ) : (
              <View style={styles.permissionContainer}>
                <Text style={styles.permissionTitle}>
                  請告知顧客授權位置權限
                </Text>
                <Text style={styles.permissionDescription}>
                  因應法規菸酒銷售需於特定場域，優惠卷兌換需取得位置資訊核銷
                </Text>
                <Text style={styles.permissionWarning}>
                  請注意！若未授權位置資訊，優惠券將無法使用
                </Text>
                <View style={styles.buttonContainer}>
                  <TouchableOpacity
                    style={styles.cancelButton}
                    onPress={() => {
                      setModalVisible(false)
                    }}
                  >
                    <Text style={styles.cancelButtonText}>取消</Text>
                  </TouchableOpacity>
                  <TouchableOpacity
                    style={styles.nextButton}
                    onPress={() => {
                      getGeolocationData()
                    }}
                  >
                    <Text style={styles.nextButtonText}>下一步</Text>
                  </TouchableOpacity>
                </View>
              </View>
            )}
          </View>
        </View>
      </Modal>
    )
  }

  const renderGetLocationErrorModal = () => {
    return (
      <Modal
        visible={getLocationErrorModalVisible}
        transparent
        animationType="fade"
        onRequestClose={onClose}
      >
        <View style={styles.overlay}>
          <View
            style={[
              styles.container,
              {
                width: Dimensions.get('window').width * 0.9,
                height: Dimensions.get('window').height * 0.8,
              },
            ]}
          >
            <View style={styles.permissionContainer}>
              <Text style={styles.permissionTitle}>
                無法使用優惠券，請允許位置權限
              </Text>
              <Text style={styles.permissionDescription}>
                未允許位置資訊，將無法使用優惠券
              </Text>
              <View
                style={[
                  Layout.fullWidth,
                  Layout.alignItemsCenter,
                  Layout.justifyContentCenter,
                ]}
              >
                <TouchableOpacity
                  style={styles.cancelButton}
                  onPress={() => {
                    onClose()
                  }}
                >
                  <Text style={styles.cancelButtonText}>我知道了</Text>
                </TouchableOpacity>
              </View>
            </View>
          </View>
        </View>
      </Modal>
    )
  }

  const renderCTAButton = () => {
    return (
      <View style={[Layout.fullWidth]}>
        <Text
          style={[
            Fonts.size14,
            Fonts.weight400,
            Fonts.textCenter,
            Gutters.smallBMargin,
            {
              color: Colors.snackbar.error,
            },
          ]}
        >
          使用時請將此畫面出示門市人員
        </Text>
        <TouchableOpacity
          style={[
            styles.ctaButton,
            {
              backgroundColor:
                couponDetail?.data.usable || isRedeeming
                  ? Colors.primary
                  : Colors.gray,
            },
          ]}
          disabled={!couponDetail?.data.usable || isRedeeming}
          onPress={() => {
            setModalVisible(true)
          }}
        >
          {isRedeeming || submitLoading ? (
            <ActivityIndicator size={'large'} color={Colors.white} />
          ) : (
            <Text
              style={[
                Fonts.size16,
                Fonts.weight500,
                { color: Colors.fontText.dark.primary2 },
              ]}
            >
              店員核銷專用（請勿誤按）
            </Text>
          )}
        </TouchableOpacity>
      </View>
    )
  }

  const handleCloseDialog = useCallback(() => {
    dispatch(globalActions.closeBottomDialog())
    setLocation(null)
    setGetLocationSuccess(false)
  }, [dispatch])

  // 打開領取的 dialog
  const handleOpenRedeemDialog = useCallback(() => {
    dispatch(
      globalActions.openBottomDialog({
        visible: true,
        disabledBackgroundClose: true,
        handleClickBackground: handleCloseDialog,
        content: (
          <View style={[Layout.center, Gutters.regularVMargin]}>
            <View
              style={[
                Layout.fullWidth,
                Layout.alignItemsCenter,
                Gutters.regularHPadding,
              ]}
            >
              <Text
                style={[
                  Fonts.weight700,
                  Fonts.size20,
                  { color: Colors.snackbar.error },
                ]}
              >
                確認核銷？
              </Text>
              <Text
                style={[
                  Fonts.weight400,
                  Fonts.size16,
                  Fonts.textCenter,
                  Gutters.smallBMargin,
                  { color: Colors.fontText.light.primary2 },
                ]}
              >
                點擊「立即核銷」後，此優惠券即視同被使用，恕不補發
              </Text>
            </View>
            <View
              style={[
                {
                  backgroundColor: Colors.fontText.light.primary2,
                  height: 1,
                  width: '100%',
                  marginVertical: 16,
                },
              ]}
            />
            <View style={[Layout.fullWidth, Gutters.regularHPadding]}>
              <TouchableOpacity
                style={[
                  Layout.fullWidth,
                  Layout.center,
                  Gutters.smallBMargin,
                  Gutters.tinyVPadding,
                  {
                    backgroundColor: Colors.primary,
                    borderRadius: BorderRadius.radius8,
                    height: Height.height48,
                  },
                ]}
                disabled={isRedeeming}
                onPress={submitCouponWithLocation}
              >
                {isRedeeming ? (
                  <ActivityIndicator size={'large'} color={Colors.white} />
                ) : (
                  <Text
                    style={[
                      Fonts.weight500,
                      Fonts.size16,
                      { color: Colors.fontText.dark.primary2 },
                    ]}
                  >
                    立即核銷
                  </Text>
                )}
              </TouchableOpacity>
              <TouchableOpacity
                style={[
                  Layout.fullWidth,
                  Layout.center,
                  Gutters.smallBMargin,
                  Gutters.tinyVPadding,
                  {
                    borderRadius: BorderRadius.radius8,
                    height: Height.height48,
                  },
                ]}
                onPress={handleCloseDialog}
              >
                <Text
                  style={[
                    Fonts.weight500,
                    Fonts.size16,
                    { color: Colors.fontText.light.primary2 },
                  ]}
                >
                  取消
                </Text>
              </TouchableOpacity>
            </View>
          </View>
        ),
      }),
    )
  }, [
    Fonts,
    Gutters,
    Layout,
    dispatch,
    handleCloseDialog,
    isRedeeming,
    submitCouponWithLocation,
  ])

  useEffect(() => {
    if (getLocationSuccess && location?.latitude && location?.longitude) {
      handleOpenRedeemDialog()
      setGetLocationSuccess(false)
    }
  }, [getLocationSuccess, handleOpenRedeemDialog, handleCloseDialog, location])

  return (
    <View style={[Layout.fill]}>
      <Header
        title={'優惠券詳情'}
        leftIcon={
          <Image
            style={[styles.arrowLeftIcon]}
            source={Images.arrowLeft}
            resizeMode="contain"
          />
        }
        leftIconPress={handleGoBack}
        rightEmptyIconWidth="50"
      />
      <ScrollView
        style={[
          Layout.fill,
          {
            backgroundColor: Colors.background.default,
            height: Dimensions.get('window').height,
          },
        ]}
        contentContainerStyle={[Layout.fill]}
      >
        {(isRedeeming || submitLoading || isLoading) && (
          <View style={[Layout.center, Layout.fill]}>
            <LoadingComponent />
          </View>
        )}
        <FadeInView duration={500} style={Layout.fill}>
          {couponDetail?.data.program.couponName && (
            <View
              style={[
                Layout.fill,
                Gutters.regularHPadding,
                Gutters.smallTPadding,
              ]}
            >
              <View
                style={[
                  Gutters.smallBMargin,
                  Gutters.smallVPadding,
                  Gutters.smallHPadding,
                  {
                    backgroundColor: Colors.background.surface,
                    borderRadius: 8,
                  },
                ]}
              >
                <View
                  style={[
                    Layout.row,
                    Layout.alignItemsCenter,
                    Layout.justifyContentBetween,
                  ]}
                >
                  <Text
                    style={[
                      Fonts.size20,
                      Fonts.weight500,
                      {
                        color: Colors.fontText.light.primary2,
                      },
                    ]}
                  >
                    {couponDetail?.data.program.couponName || ''}
                  </Text>
                  <Image
                    source={Images.coupon_list_icon}
                    style={[Gutters.regularLMargin, Layout.iconSize56]}
                    resizeMode="contain"
                  />
                </View>
              </View>
              {renderCouponDetail(
                '適用於',
                couponDetail?.data.program.applicableTarget || '',
              )}
              {renderCouponDetail(
                '使用效期',
                `${dayjs(couponDetail?.data.startedAt).format(
                  'YYYY/MM/DD:HH:mm',
                )}起~迄${dayjs(couponDetail?.data.expiresAt).format(
                  'YYYY/MM/DD:HH:mm',
                )}`,
              )}
              {!!couponDetail?.data?.program?.spirits?.length &&
                renderCouponOtherSpirits(
                  '適用品項',
                  couponDetail?.data.program.spirits || [],
                )}
              {renderCampaignLink()}
              {renderCouponDetail(
                '使用規則',
                couponDetail?.data.program.couponRules || '',
              )}
              {renderCouponDetail(
                '使用說明',
                couponDetail?.data.program.couponInstructions || '',
              )}
              {renderCTAButton()}
            </View>
          )}
        </FadeInView>
        {renderAlertModal()}
        {renderGetLocationErrorModal()}
      </ScrollView>
      <View>
        <WarningSignComponent bottomSpace />
      </View>
    </View>
  )
}

const getStyle = () =>
  StyleSheet.create({
    arrowLeftIcon: {
      width: 24,
      height: 24,
      paddingLeft: 50,
    },
    ctaButton: {
      marginTop: 'auto',
      backgroundColor: Colors.primary,
      borderRadius: 8,
      padding: 16,
      alignItems: 'center',
      marginBottom: 16,
    },
    overlay: {
      flex: 1,
      backgroundColor: 'rgba(0, 0, 0, 0.7)',
      justifyContent: 'center',
      alignItems: 'center',
      maxWidth: 500,
      marginHorizontal: 'auto',
    },
    container: {
      backgroundColor: '#FFF',
      borderRadius: 10,
      overflow: 'hidden',
      position: 'relative',
      maxHeight: 400,
      maxWidth: 500,
      marginHorizontal: 'auto',
    },
    permissionContainer: {
      padding: 20,
      flex: 1,
      justifyContent: 'center',
      alignItems: 'center',
    },
    permissionTitle: {
      fontSize: 18,
      fontWeight: 'bold',
      marginBottom: 20,
      textAlign: 'center',
    },
    permissionDescription: {
      fontSize: 16,
      marginBottom: 20,
      textAlign: 'center',
      lineHeight: 24,
    },
    permissionWarning: {
      fontSize: 14,
      color: Colors.snackbar.error,
      marginBottom: 40,
      textAlign: 'center',
      lineHeight: 20,
    },
    buttonContainer: {
      flexDirection: 'row',
      justifyContent: 'space-between',
      width: '100%',
      paddingHorizontal: 20,
    },
    cancelButton: {
      backgroundColor: '#E0E0E0',
      padding: 15,
      borderRadius: 8,
      width: '45%',
      alignItems: 'center',
    },
    cancelButtonText: {
      fontSize: 16,
      fontWeight: 'bold',
      color: '#333333',
    },
    nextButton: {
      backgroundColor: Colors.primary,
      padding: 15,
      borderRadius: 8,
      width: '45%',
      alignItems: 'center',
    },
    nextButtonText: {
      fontSize: 16,
      fontWeight: 'bold',
      color: Colors.fontText.dark.primary2,
    },
  })

export default CouponDetailContainer
