import React from 'react'
import {
  View,
  Image,
  Text,
  StyleSheet,
  Dimensions,
  TouchableOpacity,
} from 'react-native'

import { useTheme } from '@/Hooks'
import { Colors } from '@/Theme/Variables'
import { FadeInView, Header } from '@/Components'
import { AuthPagesEnum } from '@/Navigators/withAuthPages'

const CouponResultContainer = ({ navigation, route }: any) => {
  const { Layout, Images, Fonts, Gutters } = useTheme()
  const styles = getStyle()

  const { type, couponName } = route.params

  const redeemContent = type === 'success' ? '兌領成功' : '兌領失敗'
  const redeemIcon =
    type === 'success'
      ? Images.coupon_result_success_icon
      : Images.coupon_result_failed_icon

  const renderCTAButton = () => {
    return (
      <TouchableOpacity
        style={[
          styles.ctaButton,
          {
            backgroundColor: Colors.primary,
          },
        ]}
        onPress={() => {
          navigation.navigate(AuthPagesEnum.CouponListContainer)
        }}
      >
        <Text
          style={[
            Fonts.size16,
            Fonts.weight500,
            { color: Colors.fontText.dark.primary2 },
          ]}
        >
          返回優惠券
        </Text>
      </TouchableOpacity>
    )
  }

  return (
    <View
      style={[
        Layout.fill,
        {
          backgroundColor: Colors.background.default,
          height: Dimensions.get('window').height,
        },
      ]}
    >
      <Header
        title={'兌換結果'}
        leftIcon={
          <Image
            style={[styles.arrowLeftIcon]}
            source={Images.arrowLeft}
            resizeMode="contain"
          />
        }
        rightEmptyIconWidth="50"
      />
      <FadeInView duration={500} style={Layout.fill}>
        <View
          style={[
            Gutters.smallBMargin,
            Gutters.smallVPadding,
            Gutters.smallHPadding,
            { backgroundColor: Colors.background.surface },
          ]}
        >
          <View
            style={[
              Layout.row,
              Layout.alignItemsCenter,
              Layout.justifyContentBetween,
            ]}
          >
            <Text
              style={[
                Fonts.size20,
                Fonts.weight500,
                {
                  color: Colors.fontText.light.primary2,
                },
              ]}
            >
              {couponName}
            </Text>
            <Image
              source={Images.coupon_list_icon}
              style={[Gutters.regularLMargin, Layout.iconSize56]}
              resizeMode="contain"
            />
          </View>
        </View>
        <View
          style={[
            Layout.fill,
            Gutters.regularHPadding,
            {
              paddingTop: 200,
            },
          ]}
        >
          <View style={[Layout.center, Gutters.largeBMargin]}>
            <Image
              style={[Layout.iconSize56, Gutters.regularBMargin]}
              source={redeemIcon}
              resizeMode="contain"
            />
            <Text
              style={[
                Fonts.size16,
                Fonts.weight500,
                { color: Colors.fontText.light.primary2 },
              ]}
            >
              {redeemContent}
            </Text>
          </View>
          {renderCTAButton()}
        </View>
      </FadeInView>
    </View>
  )
}

const getStyle = () =>
  StyleSheet.create({
    arrowLeftIcon: {
      width: 24,
      height: 24,
      paddingLeft: 50,
    },
    ctaButtonContainer: {
      position: 'absolute',
      bottom: 0,
      width: '100%',
    },
    ctaButton: {
      backgroundColor: Colors.primary,
      borderRadius: 8,
      padding: 16,
      alignItems: 'center',
      marginBottom: 16,
    },
  })

export default CouponResultContainer
