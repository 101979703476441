import React, { useCallback, useState, useEffect, useRef } from 'react'
import {
  View,
  TouchableOpacity,
  Image,
  Text,
  StyleSheet,
  Dimensions,
  useWindowDimensions,
  ActivityIndicator,
  FlatList,
  TextInput,
} from 'react-native'
import { useSelector } from 'react-redux'
import { useDispatch } from 'react-redux'
import { TabView, SceneMap, TabBar } from 'react-native-tab-view'
import dayjs from 'dayjs'

import { useTheme } from '@/Hooks'
import { Colors } from '@/Theme/Variables'
import { FadeInView, Header, WarningSignComponent } from '@/Components'
import { RootState } from '@/Store'
import { useIsFocused } from '@react-navigation/native'
import { AuthPagesEnum } from '@/Navigators/withAuthPages'
import { PagesEnum } from '@/Navigators/Application'
import loyaltyProgramsApi from '@/Services/modules/loyaltyPrograms'
import { couponActions } from '@/Store/Coupon'
import { CouponListStatus } from '@/Services/modules/loyaltyPrograms/loyaltyPrograms.type'
import SearchTextInput from './components/searchTextInput'
import useDebounce from '@/Util/useDebounce'
import { removeEmptyKeys } from '@/Util/global'

export enum CouponStatus {
  USABLE = 'usable',
  REDEEMED = 'redeemed',
  INVALID = 'invalid',
}

export enum CouponStatusIndex {
  USABLE = 0,
  REDEEMED = 1,
  INVALID = 2,
}

const CouponListContainer = ({ navigation }: any) => {
  const textInputRef = useRef<TextInput>(null)
  const { Layout, Images, Fonts, Gutters } = useTheme()
  const isFocused = useIsFocused()
  const dispatch = useDispatch()
  const layout = useWindowDimensions()
  const { couponList } = useSelector((state: RootState) => state.coupon)

  const [
    getCouponListRequest,
    { isLoading: getCouponListIsLoading, isFetching: getCouponListIsFetching },
  ] = loyaltyProgramsApi.useLazyGetCouponListQuery()

  const [index, setIndex] = useState(CouponStatusIndex.USABLE)
  const [routes] = useState([
    { key: 'Usable', title: '可使用' },
    { key: 'Redeemed', title: '已使用' },
    { key: 'Invalid', title: '失效' },
  ])
  const [searchKeyword, setSearchKeyword] = useState('')

  const styles = getStyle()

  // 根據當前 tab 獲取對應狀態類型
  const getStatusByIndex = useCallback((tabIndex: number) => {
    switch (tabIndex) {
      case CouponStatusIndex.USABLE:
        return CouponListStatus.USABLE
      case CouponStatusIndex.REDEEMED:
        return CouponListStatus.REDEEMED
      case CouponStatusIndex.INVALID:
        return CouponListStatus.INVALID
      default:
        return CouponListStatus.USABLE
    }
  }, [])

  // 只獲取當前 tab 的資料
  const fetchCurrentTabData = useCallback(
    (tabIndex: number, query: string = '', cursor: string = '') => {
      const status = getStatusByIndex(tabIndex)
      const payload = {
        status,
        query,
        perPage: 10,
        cursor,
      }
      getCouponListRequest(removeEmptyKeys(payload))
    },
    [getCouponListRequest, getStatusByIndex],
  )

  // 初始獲取資料，只獲取當前 tab
  const initFetchCouponList = useCallback(() => {
    fetchCurrentTabData(index)
  }, [fetchCurrentTabData, index])

  // TODO: workaround 強制刷新
  useEffect(() => {
    if (isFocused) {
      initFetchCouponList()
    }

    return () => {
      dispatch(couponActions.clearCouponList())
    }
  }, [dispatch, isFocused, initFetchCouponList])

  // 當 tab 變更時，重新獲取資料
  useEffect(() => {
    dispatch(couponActions.clearCouponList())
    fetchCurrentTabData(index)
  }, [dispatch, index, fetchCurrentTabData])

  const handleClickCard = (id: string) => {
    navigation.navigate(AuthPagesEnum.CouponDetailContainer, { id })
  }

  const handleClearSearchKeyword = () => {
    setSearchKeyword('')
    dispatch(couponActions.clearCouponList())
  }

  const handleFetchMoreData = () => {
    let nextCursor = ''

    switch (index) {
      case CouponStatusIndex.USABLE:
        nextCursor = couponList?.usable?.meta?.nextCursor || ''
        break
      case CouponStatusIndex.REDEEMED:
        nextCursor = couponList?.redeemed?.meta?.nextCursor || ''
        break
      case CouponStatusIndex.INVALID:
        nextCursor = couponList?.invalid?.meta?.nextCursor || ''
        break
    }

    if (nextCursor) {
      fetchCurrentTabData(index, searchKeyword, nextCursor)
    }
  }

  const handleFetchSearchData = () => {
    if (!searchKeyword) {
      fetchCurrentTabData(index)
      return
    }

    dispatch(couponActions.clearCouponList())
    fetchCurrentTabData(index, searchKeyword)
  }

  useDebounce(handleFetchSearchData, 1000, [searchKeyword])

  const handleChangeIndex = (indexValue: number) => {
    setIndex(indexValue)
    setSearchKeyword('')
  }

  const couponCard = (item: any, disabled: boolean) => {
    return (
      <View
        style={[
          Gutters.smallBMargin,
          Gutters.smallVPadding,
          Gutters.smallHPadding,
          { backgroundColor: Colors.background.surface, borderRadius: 8 },
        ]}
      >
        <View style={[Layout.row, Layout.justifyContentBetween]}>
          <Text
            style={[
              Fonts.size20,
              Fonts.weight500,
              {
                color: disabled
                  ? Colors.fontText.light.primary3
                  : Colors.fontText.light.primary2,
              },
            ]}
          >
            {item.program.couponName}
          </Text>
          <Image
            source={
              disabled
                ? Images.coupon_list_disabled_icon
                : Images.coupon_list_icon
            }
            style={[Gutters.regularLMargin, Layout.iconSize56]}
            resizeMode="contain"
          />
        </View>
        <View style={[Gutters.smallBMargin]}>
          <Text
            style={[
              Fonts.size14,
              Fonts.weight400,
              { color: Colors.fontText.light.primary3 },
            ]}
          >{`適用門市：${item.program.applicableTarget}`}</Text>
          <Text
            style={[
              Fonts.size14,
              Fonts.weight400,
              { color: Colors.fontText.light.primary3 },
            ]}
          >{`有效期限：${dayjs(item.startedAt).format('YYYY-MM-DD')}~${dayjs(
            item.expiresAt,
          ).format('YYYY-MM-DD')}`}</Text>
        </View>
        {!disabled && (
          <>
            <View
              style={[
                Layout.fullWidth,
                Gutters.miniVMargin,
                { height: 1, backgroundColor: Colors.background.onSurface },
              ]}
            />

            <TouchableOpacity
              style={[Layout.center, Gutters.miniTPadding]}
              onPress={() => handleClickCard(item?.id)}
            >
              <Text
                style={[
                  Fonts.size14,
                  Fonts.weight500,
                  { color: Colors.primary },
                ]}
              >
                查看使用
              </Text>
            </TouchableOpacity>
          </>
        )}
      </View>
    )
  }

  const renderEmpty = () => {
    if (getCouponListIsLoading || getCouponListIsFetching) {
      return (
        <View style={[Layout.fill, Layout.center, { paddingTop: 90 }]}>
          <ActivityIndicator size="large" color={Colors.primary} />
        </View>
      )
    }
    return (
      <View style={[Layout.fill, Layout.center, { paddingTop: 90 }]}>
        <Image
          source={Images.upload_spirits_empty}
          resizeMode="contain"
          style={[Gutters.smallBMargin, { width: 73, height: 130 }]}
        />
        <Text
          style={[
            Fonts.size16,
            Fonts.weight700,
            { color: Colors.fontText.light.primary2 },
          ]}
        >
          尚無資料
        </Text>
      </View>
    )
  }

  const renderUsable = () => {
    return (
      <View style={[Layout.fill]}>
        <FlatList
          data={couponList?.usable?.data || []}
          contentContainerStyle={[{ paddingBottom: 200 }]}
          keyExtractor={item => item?.id}
          renderItem={({ item }) => couponCard(item, false)}
          refreshing
          onEndReached={({ distanceFromEnd }) => {
            if (distanceFromEnd >= 1) {
              handleFetchMoreData()
            }
          }}
          onEndReachedThreshold={1}
          ListEmptyComponent={renderEmpty}
        />
      </View>
    )
  }

  const renderRedeemed = () => {
    return (
      <View style={[Layout.fill]}>
        <FlatList
          data={couponList?.redeemed?.data || []}
          contentContainerStyle={[{ paddingBottom: 200 }]}
          keyExtractor={item => item?.id}
          renderItem={({ item }) => couponCard(item, true)}
          refreshing
          onEndReached={({ distanceFromEnd }) => {
            if (distanceFromEnd >= 1) {
              handleFetchMoreData()
            }
          }}
          onEndReachedThreshold={1}
          ListEmptyComponent={renderEmpty}
        />
      </View>
    )
  }

  const renderInvalid = () => {
    return (
      <View style={[Layout.fill]}>
        <FlatList
          data={couponList?.invalid?.data || []}
          contentContainerStyle={[{ paddingBottom: 200 }]}
          keyExtractor={item => item?.id}
          renderItem={({ item }) => couponCard(item, true)}
          refreshing
          onEndReached={({ distanceFromEnd }) => {
            if (distanceFromEnd >= 1) {
              handleFetchMoreData()
            }
          }}
          onEndReachedThreshold={1}
          ListEmptyComponent={renderEmpty}
        />
      </View>
    )
  }

  const renderScene = SceneMap({
    Usable: renderUsable,
    Redeemed: renderRedeemed,
    Invalid: renderInvalid,
  })

  const renderTabBar = (props: any) => (
    <TabBar
      {...props}
      indicatorStyle={{ backgroundColor: Colors.primary, height: 4 }}
      style={{ backgroundColor: Colors.background.default }}
    />
  )

  const handleGoBack = useCallback(() => {
    if (navigation.canGoBack()) {
      navigation.goBack()
    } else {
      navigation.navigate(PagesEnum.Main, {
        screen: 'Profile',
      })
    }
  }, [navigation])

  return (
    <View
      style={[
        Layout.fill,
        {
          backgroundColor: Colors.background.default,
          height: Dimensions.get('window').height,
        },
      ]}
    >
      <Header
        title={'優惠券'}
        leftIcon={
          <Image
            style={[styles.arrowLeftIcon]}
            source={Images.arrowLeft}
            resizeMode="contain"
          />
        }
        leftIconPress={handleGoBack}
        rightEmptyIconWidth="50"
      />
      <FadeInView duration={500} style={Layout.fill}>
        <View style={[Layout.fill]}>
          <View style={[Gutters.regularHPadding]}>
            <SearchTextInput
              textInputRef={textInputRef}
              searchKeyword={searchKeyword}
              setSearchKeyword={setSearchKeyword}
              handleClearSearchKeyword={handleClearSearchKeyword}
              handleFetchSearchData={handleFetchSearchData}
            />
          </View>
          <TabView
            navigationState={{ index, routes }}
            renderScene={renderScene}
            renderTabBar={renderTabBar}
            onIndexChange={handleChangeIndex}
            initialLayout={{ width: layout.width }}
            sceneContainerStyle={[
              Gutters.regularHPadding,
              Gutters.regularVMargin,
            ]}
          />
        </View>
      </FadeInView>
      <View>
        <WarningSignComponent bottomSpace />
      </View>
    </View>
  )
}

const getStyle = () =>
  StyleSheet.create({
    arrowLeftIcon: {
      width: 24,
      height: 24,
      paddingLeft: 50,
    },
  })

export default CouponListContainer
