import { EventName, sendIframeMessage } from '../index'
import { getConfig } from '@/Util/global'
import { prefixProxyEndpoint } from '@/Services/api'
import { getParam, navigate, setPendingRedirect } from '@/Navigators/utils'
import { AuthPagesEnum } from '@/Navigators/withAuthPages'
import { PagesEnum } from '@/Navigators/Application'
import { Dispatch } from '@reduxjs/toolkit'
import { couponActions } from '@/Store/Coupon'
import { couponReferral } from '@/Util/couponReferral'
import { globalActions } from '@/Store/Global'
import gtag, { GA_EVENT_NAME } from '@/Util/gtag'

import i09SpecialOfferFactory, { USER_DEFINED_ID } from './factory'

export const CAMPAIGN_EVENT_NAME = 'i09-special-offer'
const PIXEL_EVENT_COUPON_COLLECTED_NAME = 'CouponCollected'

const GET_COUPON_LIST = 'GET_COUPON_LIST'
const COUPON_LIST_RESPONSE = 'COUPON_LIST_RESPONSE'
const CLAIM_COUPON_REQUEST_NOT_LOGIN = 'CLAIM_COUPON_REQUEST_NOT_LOGIN'
const CLAIM_COUPON = 'CLAIM_COUPON'
const TO_COUPON_LIST = 'TO_COUPON_LIST'
const TO_LOGIN = 'TO_LOGIN'
const SHARE_BUTTON = 'SHARE_BUTTON'
const COUPON_LIST_ICON_CLICK = 'COUPON_LIST_ICON_CLICK'

interface I09SpecialOfferProps {
  data: any
  isLogin: boolean
  dispatch: Dispatch
}

const getURL = (url: string) => {
  return getConfig().ENV === 'local'
    ? prefixProxyEndpoint(url)
    : `${getConfig().API_URL}${url}`
}

// 取得優惠券列表
const GET_COUPON_LIST_REQUEST_URL = getURL('/v1/loyalty-programs:fetch-by-uid')
const getCouponListRequest = async () => {
  try {
    const response = await fetch(GET_COUPON_LIST_REQUEST_URL, {
      method: 'POST',
      body: JSON.stringify({ userDefinedIds: USER_DEFINED_ID }),
      headers: {
        'Content-Type': 'application/json',
        'Accept-Language': 'zh',
      },
      credentials: 'include',
    }).then(res => res.json())
    return i09SpecialOfferFactory.handleResponse(response)
  } catch (error) {
    console.log('error', error)
  }
}

// 領取優惠券
const CLAIM_COUPON_REQUEST_URL = getURL('/v1/coupons')

const claimCouponRequest = async ({
  programId,
  referralCode,
  spiritId,
}: {
  programId: string
  referralCode: string
  spiritId: string
}) => {
  try {
    const response = await fetch(CLAIM_COUPON_REQUEST_URL, {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
        'Accept-Language': 'zh',
      },
      credentials: 'include',
      body: JSON.stringify({
        programId,
        referralCode,
        spiritId,
      }),
    }).then(res => {
      gtag({
        eventName: GA_EVENT_NAME.COUPON_COLLECTED,
        params: {
          from_campaign: 1,
          campaign_name: CAMPAIGN_EVENT_NAME,
        },
      })
      // @ts-ignore
      if (window?.fbq) {
        // @ts-ignore
        window?.fbq('trackCustom', PIXEL_EVENT_COUPON_COLLECTED_NAME)
      }
      return res.json()
    })
    return response
  } catch (error) {
    console.log('error', error)
  }
}

export const handleI09SpecialOffer = async ({
  data,
  isLogin,
  dispatch,
}: I09SpecialOfferProps) => {
  if (data.type === EventName.I09_SPECIAL_OFFER) {
    // 取得優惠券列表
    if (data.action === GET_COUPON_LIST) {
      await getCouponList({ isLogin })
    }
    // 領取優惠券
    if (data.action === CLAIM_COUPON) {
      if (isLogin) {
        const referralCode = await couponReferral.validateReferralCode()
        const payload = {
          programId: data.data.programId,
          referralCode: referralCode,
          spiritId: data.data.spiritId,
        }
        await claimCouponRequest(payload)
        return
      }
      sendIframeMessage({
        type: CLAIM_COUPON_REQUEST_NOT_LOGIN,
      })
    }
    // 跳轉至優惠券列表
    if (data.action === TO_COUPON_LIST) {
      const params: any = getParam()
      setPendingRedirect(PagesEnum.CampaignSite, {
        url: params?.url || '',
      })
      navigate(AuthPagesEnum.CouponListContainer, {})
    }

    if (data.action === TO_LOGIN) {
      const params: any = getParam()
      setPendingRedirect(PagesEnum.CampaignSite, {
        url: params?.url || '',
        from: 'login',
      })
      // 將優惠券資料儲存至 redux
      dispatch(
        couponActions.setClaimCoupon({
          programId: data.data.programId,
          referralCode: data.data.referralCode,
          spiritId: data.data.spiritId,
        }),
      )
      navigate(PagesEnum.LoginMainContainer, {})
    }

    // 點擊分享按鈕
    if (data.action === SHARE_BUTTON) {
      const shareLink = 'https://lihi.cc/hatVQ'
      // @ts-ignore
      navigator.clipboard.writeText(shareLink).then(
        () => {
          dispatch(
            globalActions.openSnackbar({
              visible: true,
              message: '連結已複製到剪貼簿',
              type: 'default',
              showIcon: false,
            }),
          )
        },
        () => {
          console.error('Failed to copy to clipboard')
        },
      )
    }

    // 點擊優惠券列表圖示
    if (data.action === COUPON_LIST_ICON_CLICK) {
      if (!isLogin) {
        const params: any = getParam()
        setPendingRedirect(PagesEnum.CampaignSite, {
          url: params?.url || '',
        })
        navigate(PagesEnum.LoginMainContainer, {})
        return
      }
      navigate(AuthPagesEnum.CouponListContainer, {})
    }
  }
}

// 取得優惠券列表
const getCouponList = async ({ isLogin }: { isLogin: boolean }) => {
  const response = await getCouponListRequest()
  sendIframeMessage({
    type: COUPON_LIST_RESPONSE,
    data: response,
    isLogin,
  })
}
