import React from 'react'
import {
  View,
  TextInput,
  TouchableOpacity,
  Image,
  StyleSheet,
} from 'react-native'

import { useTheme } from '@/Hooks'
import { Colors } from '@/Theme/Variables'

interface SearchTextInputProps {
  textInputRef: React.RefObject<TextInput>
  searchKeyword: string
  setSearchKeyword: (text: string) => void
  handleClearSearchKeyword: () => void
  handleFetchSearchData: () => void
}

// 這個元件 workaround 在 tab 裡面輸入匡會一直重新渲染無法連續輸入
const SearchTextInput = ({
  textInputRef,
  searchKeyword,
  setSearchKeyword,
  handleClearSearchKeyword,
  handleFetchSearchData,
}: SearchTextInputProps) => {
  const { Layout, Gutters, Images } = useTheme()

  const styles = getStyle(searchKeyword)

  return (
    <View
      style={[Layout.row, styles.searchInputContainer, Gutters.smallHPadding]}
    >
      <TextInput
        ref={textInputRef}
        style={[styles.searchInput, { color: Colors.white }]}
        value={searchKeyword}
        placeholder="搜尋優惠券"
        placeholderTextColor={Colors.gray}
        returnKeyType="search"
        returnKeyLabel="search"
        onChangeText={setSearchKeyword}
        onSubmitEditing={handleFetchSearchData}
      />
      {!!searchKeyword?.length && (
        <TouchableOpacity onPress={handleClearSearchKeyword}>
          <Image
            source={Images.close}
            style={Layout.iconSize24}
            resizeMode="contain"
          />
        </TouchableOpacity>
      )}
    </View>
  )
}

const getStyle = (searchKeyword: string) =>
  StyleSheet.create({
    searchInputContainer: {
      width: '100%',
      height: 40,
      backgroundColor: Colors.background.default,
      borderRadius: 8,
      borderColor: searchKeyword?.length
        ? Colors.primary
        : 'rgba(255, 255, 255, 0.6)',
      borderWidth: 1,
      alignItems: 'center',
      marginBottom: 16,
    },
    searchInput: {
      width: '90%',
      marginLeft: 4,
      outlineWidth: 0,
      outlineColor: 'transparent',
      outlineStyle: 'none',
    },
  })

export default React.memo(SearchTextInput)
