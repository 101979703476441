import React, {
  useCallback,
  useEffect,
  useLayoutEffect,
  useMemo,
  useRef,
  useState,
} from 'react'
import {
  View,
  TouchableOpacity,
  Image,
  Text,
  StyleSheet,
  FlatList,
  ActivityIndicator,
  TextInput,
  Dimensions,
  Keyboard,
} from 'react-native'
import { Appbar } from 'react-native-paper'
import { useTranslation } from 'react-i18next'
import { useSelector, useDispatch } from 'react-redux'

import { useTheme } from '@/Hooks'
import { BorderRadius, Colors, Height } from '@/Theme/Variables'
import {
  FadeInView,
  SpiritCard,
  WarningSignComponent,
  AdvertiseList,
  AdvertiseComponent,
} from '@/Components'
import { spiritsApi } from '@/Services/modules/spirits'
import useDebounce from '@/Util/useDebounce'
import AsyncStorage from '@react-native-async-storage/async-storage'
import { capitalizedSentence, removeEmptyKeys } from '@/Util/global'
import { RootState, getGlobal } from '@/Store'
import { LogSearchTextResultEnum, logApi } from '@/Services/logApi'
import { navigate } from '@/Navigators/utils'
import { spiritActions } from '@/Store/Spirit'
import { SpiritCardHeight } from '@/Components/SpiritCard'
import { globalActions } from '@/Store/Global'
import userApi from '@/Services/modules/users'
import { DownLoadSign } from '@/Components'
import { AuthPagesEnum } from '@/Navigators/withAuthPages'
import { PagesEnum } from '@/Navigators/Application'
import { MainPagesEnum } from '@/Navigators/Main'
import { advertiseApi, AdvertiseSpaceEnum } from '@/Services/advertiseApi'
import gtag, { GA_EVENT_NAME } from '@/Util/gtag'

import SortModal, { SORT_BTN_ARR } from './components/sortModal'
import {
  convertParams,
  convertPrice,
  convertRating,
  separateFlavors,
} from './filterModal/MainFilterModal'
import useGetFlavorData from './hooks/useGetFlavorData'
import useGetBrandsData from './hooks/useGetBrandsData'

// 定義一個新的組件
const BrandItem: React.FC<{
  brandName: string
  onPress: (brandName: string) => void
}> = ({ brandName, onPress }) => {
  const { Layout, Gutters, Images, Fonts } = useTheme()
  return (
    <TouchableOpacity
      key={brandName}
      style={[Layout.fullWidth, Layout.rowHCenter, Gutters.smallBMargin]}
      onPress={() => onPress(brandName)}
    >
      <Image
        source={Images.search_list_suggest_brand}
        style={[Layout.iconSize32, Gutters.tinyRMargin]}
        resizeMode="contain"
      />

      <Text
        style={[
          Fonts.size14,
          Fonts.weight500,
          {
            color: Colors.fontText.light.primary2,
          },
        ]}
      >
        {capitalizedSentence(brandName)}
      </Text>
    </TouchableOpacity>
  )
}

const SearchListContainer = ({ route, navigation }: any) => {
  const { Layout, Gutters, Images, Fonts } = useTheme()
  const dispatch = useDispatch()
  const { t } = useTranslation()
  const { language } = useSelector((state: RootState) => state.theme)
  const { searchList: searchListData, searchListTitleAdvertiseIndex } =
    useSelector((state: RootState) => state.spirit)
  const globalState = useSelector(getGlobal)
  const { productImageLightBox } = globalState
  const textInputRef = useRef<any>()
  const flatListRef = useRef<any>()

  const { flavorsData, flavorIsFinish } = useGetFlavorData()

  const { data: getMeData } = userApi.useGetMeQuery()

  const [
    getSearchSpiritsRequest,
    {
      isSuccess: isGetSearchSpiritsSuccess,
      isLoading: isGetSearchSpiritsLoading,
      isFetching: isGetSearchSpiritsFetching,
    },
  ] = spiritsApi.useLazyGetSearchSpiritsQuery()

  // 紀錄文字搜尋 log 後續點擊卡片行為
  const [lazyGetSearchLogRequest] = logApi.useLazyGetSearchLogQuery()

  // 記錄文字搜尋的 keyword log
  const [lazyGetSearchResultLogRequest] =
    logApi.useLazyGetSearchResultLogQuery()

  // 廣告資料
  const { data: advertiseData } = advertiseApi.useGetAdvertiseInfoQuery(
    {
      space: [
        AdvertiseSpaceEnum.TEXT_SEARCH_LIST,
        AdvertiseSpaceEnum.TEXT_SEARCH_TITLE,
      ],
    },
    { refetchOnMountOrArgChange: 10 },
  )

  // 所有品牌資訊
  const { brandsData } = useGetBrandsData()

  const [searchKeyword, setSearchKeyword] = useState<string>(
    route?.params?.q ? route?.params?.q : '',
  )
  // 除了 q 之外的 params
  const [filters, setFilters] = useState<any>({})
  const [page, setPage] = useState<number>(1)
  const [visible, setVisible] = useState(false)
  const [sortValue, setSortValue] = useState<{
    label: string
    value: string
  }>(SORT_BTN_ARR[0])

  const showModal = () => setVisible(true)
  const hideModal = () => setVisible(false)

  const handleSort = () => {
    setSortValue(sortValue)
  }

  const initFilters = useMemo(() => Object.keys(filters)?.length, [filters])

  // 使用者搜尋文字包含的品牌前三名
  const searchBrandData = useMemo(() => {
    if (!brandsData) {
      return []
    }
    return brandsData
      .filter(
        item =>
          item.zhName.includes(searchKeyword) ||
          item.enName.includes(searchKeyword),
      )
      .map(item => {
        if (item.zhName.includes(searchKeyword)) {
          return item.zhName
        } else if (item.enName.includes(searchKeyword)) {
          return item.enName
        }
      })
      .splice(0, 3)
  }, [brandsData, searchKeyword])

  const showEmptyComponentCondition = useMemo(() => {
    // 在類別搜尋頁面直接回傳 true , 在文字搜尋頁面需要等到 api 回傳以及有 keyword 才能判斷
    if (initFilters) {
      return true
    } else {
      return isGetSearchSpiritsSuccess && !!searchKeyword?.length
    }
  }, [initFilters, isGetSearchSpiritsSuccess, searchKeyword?.length])

  const styles = getStyle(searchKeyword, textInputRef)

  const handleClearSearchKeyword = useCallback(() => {
    setSearchKeyword('')
    dispatch(spiritActions.clearSearchList())
    setPage(1)
    textInputRef?.current?.focus()
    navigation.setParams({ q: undefined })
  }, [dispatch, navigation])

  const handleGoBack = useCallback(() => {
    handleClearSearchKeyword()
    dispatch(spiritActions.clearSearchList())
    setPage(1)
    setFilters({})

    if (navigation.canGoBack()) {
      navigation.goBack()
      return
    }
    navigation.navigate(PagesEnum.Main, {
      screen: MainPagesEnum.Home,
    })
  }, [dispatch, handleClearSearchKeyword, navigation])

  const paramsObj = useMemo(() => {
    const { parents, children } = separateFlavors(
      route?.params?.flavor,
      flavorsData,
    )

    const result = {
      averageRating: convertRating(route?.params?.rating),
      marketPriceZh: convertPrice(
        route?.params?.minPrice,
        route?.params?.maxPrice,
      ),
      category: convertParams(route?.params?.category),
      categoryNot: convertParams(route?.params?.categoryNot),
      'flavors.slug': convertParams(parents),
      'flavors.children': convertParams(children),
      brand: convertParams(route?.params?.brand),
      country: convertParams(route?.params?.country),
      pairings: convertParams(route?.params?.pairings),
      region: convertParams(route?.params?.region),
    }

    return result
  }, [route?.params, flavorsData])

  // 計算篩選有幾個篩選條件
  const convertFilterItemNumber = useCallback(
    (type: 'all' | 'brand') => {
      if (!route.params) {
        return 0
      }
      const category = convertParams(route.params?.category)
      const flavor = convertParams(route.params?.flavor)
      const brand = convertParams(route.params?.brand)
      const country = convertParams(route.params?.country)
      const region = convertParams(route.params?.region)
      const pairings = convertParams(route.params?.pairings)

      let number = 0

      if (category) {
        number += category?.split(',').length
      }
      if (flavor) {
        number += flavor?.split(',').length
      }
      if (brand) {
        number += brand?.split(',').length
      }
      if (country) {
        number += country?.split(',').length
      }
      if (region) {
        number += region?.split(',').length
      }
      if (pairings) {
        number += pairings?.split(',').length
      }

      const brandNum = brand?.split(',').length || 0

      return type === 'all' ? number : brandNum
    },
    [route.params],
  )

  // 將 metadata 放入 filters 中
  useLayoutEffect(() => {
    if (!flavorIsFinish) {
      return
    }

    if (route?.params?.redirectTo) {
      navigation.setParams({
        redirectTo: undefined,
      })
    }

    const paramsSort =
      SORT_BTN_ARR.find(item => item.value === route?.params?.sorts) ||
      SORT_BTN_ARR[0]

    setSortValue(paramsSort)
    setFilters(removeEmptyKeys(paramsObj))

    dispatch(spiritActions.clearSearchList())

    const notHasQueryAndFilters =
      !route?.params?.q && !convertFilterItemNumber('all')

    // 不打 api
    if (notHasQueryAndFilters) {
      return
    }

    getSearchSpiritsRequest(
      removeEmptyKeys({
        query: route?.params?.q,
        filters: removeEmptyKeys(paramsObj),
        sorts: paramsSort.value,
        page: 1,
      }),
    )
  }, [
    dispatch,
    flavorIsFinish,
    getSearchSpiritsRequest,
    navigation,
    paramsObj,
    route?.params,
    convertFilterItemNumber,
  ])

  useEffect(() => {
    setPage(1)
  }, [])

  useEffect(() => {
    if (textInputRef.current) {
      textInputRef.current.focus()
    }
  }, [initFilters])

  // 紀錄文字搜尋的 keyword log
  useEffect(() => {
    if (
      route?.params?.q &&
      isGetSearchSpiritsSuccess &&
      !!searchListData?.requestId?.length
    ) {
      const hasResult =
        searchListData?.data?.length > 0 &&
        searchListData?.meta?.totalRows !== 0

      lazyGetSearchResultLogRequest({
        query: route?.params?.q,
        request_id: searchListData?.requestId,
        has_result: hasResult
          ? LogSearchTextResultEnum.HAS_RESULT
          : LogSearchTextResultEnum.NO_RESULT,
      })
    }
  }, [
    isGetSearchSpiritsSuccess,
    lazyGetSearchResultLogRequest,
    route?.params?.q,
    searchListData,
  ])

  // 如果清空 searchKeyword，則清空 boozesList
  useEffect(() => {
    if (!searchKeyword) {
      handleClearSearchKeyword()
    }
  }, [handleClearSearchKeyword, searchKeyword])

  const handleFetchMoreData = useCallback(() => {
    const isNeedFetch =
      searchListData.meta.currentPage !== searchListData.meta.totalPages &&
      page < (searchListData.meta.totalPages || 0)

    if (isNeedFetch) {
      const nextPage = page + 1
      const params = {
        query: searchKeyword || '',
        filters: filters || '',
        sorts: sortValue.value,
        page: nextPage,
      }
      getSearchSpiritsRequest(removeEmptyKeys(params))
      setPage(nextPage)
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [filters, getSearchSpiritsRequest, searchKeyword, searchListData.meta])

  // title 廣告每次進來都要輪流播放（播放的值放在 store 中計算）
  useEffect(() => {
    dispatch(spiritActions.setSearchListTitleAdvertise())
  }, [dispatch])

  // 播放到最後一個重置
  useEffect(() => {
    const titleAdvertise =
      advertiseData?.data?.[AdvertiseSpaceEnum.TEXT_SEARCH_TITLE] || []
    if (searchListTitleAdvertiseIndex >= titleAdvertise?.length) {
      dispatch(spiritActions.clearSearchListTitleAdvertise())
    }
  }, [searchListTitleAdvertiseIndex, dispatch, advertiseData?.data])

  const fetchData = useCallback(() => {
    if (searchKeyword?.length) {
      const params = {
        query: searchKeyword || '',
        filters: removeEmptyKeys(paramsObj),
        sorts: sortValue.value,
        page: 1,
      }

      dispatch(spiritActions.clearSearchList())
      getSearchSpiritsRequest(removeEmptyKeys(params))
      navigation.setParams({ q: searchKeyword })

      gtag({
        eventName: GA_EVENT_NAME.VIEW_SEARCH_RESULTS,
        params: {
          search_term: searchKeyword,
        },
      })
    }
  }, [
    dispatch,
    getSearchSpiritsRequest,
    navigation,
    paramsObj,
    searchKeyword,
    sortValue.value,
  ])

  useDebounce(fetchData, 1500, [searchKeyword])

  const openLoginDialog = () => {
    dispatch(
      globalActions.openBottomDialog({
        visible: true,
        title: t('camera.bottomDialogTitle'),
        disabledBackgroundClose: false,
        content: (
          <View
            style={[
              Layout.center,
              Gutters.regularVMargin,
              Gutters.regularHPadding,
            ]}
          >
            <Image
              source={Images.bottom_dialog_login_leave}
              resizeMode="contain"
              style={[Gutters.regularBMargin, Layout.iconSize64]}
            />
            <TouchableOpacity
              style={[
                Layout.fullWidth,
                Layout.center,
                Gutters.smallBMargin,
                Gutters.tinyVPadding,
                {
                  backgroundColor: Colors.primary,
                  borderRadius: BorderRadius.radius8,
                  height: Height.height48,
                },
              ]}
              onPress={() => {
                dispatch(globalActions.closeBottomDialog())
                navigation.navigate(PagesEnum.LoginMainContainer)
              }}
            >
              <Text
                style={[
                  Fonts.weight500,
                  Fonts.size14,
                  { color: Colors.primaryText },
                ]}
              >
                {t('camera.bottomDialogButton1')}
              </Text>
            </TouchableOpacity>
            <TouchableOpacity
              style={[
                Layout.fullWidth,
                Layout.center,
                Gutters.smallBMargin,
                Gutters.tinyVPadding,
                {
                  height: Height.height48,
                },
              ]}
              onPress={() => {
                dispatch(globalActions.closeBottomDialog())
              }}
            >
              <Text style={{ color: Colors.white }}>
                {t('camera.bottomDialogButton2')}
              </Text>
            </TouchableOpacity>
          </View>
        ),
      }),
    )
  }

  const handleClickReportSpirit = () => {
    if (!getMeData) {
      openLoginDialog()
      return
    }
    navigation.navigate(AuthPagesEnum.UploadSpiritsDetailContainer)
  }

  const handleClickCard = (
    id: string,
    titleUrlSlug: string,
    itemPage: number,
    selectedRow: number,
    requestId: string,
  ) => {
    if (searchKeyword) {
      AsyncStorage.setItem('searchKeyword', searchKeyword)
    }
    if (filters) {
      AsyncStorage.setItem('searchFilters', JSON.stringify(filters))
    }
    // 如果是文字搜尋的話，要帶上 searchKeyword , page , selectedRow 打 log
    if (!initFilters) {
      lazyGetSearchLogRequest({
        page: itemPage,
        selected_row: selectedRow,
        request_id: requestId,
        selected_spirit_id: id,
      })
    }
    navigate(PagesEnum.ProductDetail, {
      lang: language,
      titleUrlSlug,
      id,
    })
  }

  const handleSortSubmit = (value: string) => {
    hideModal()
    navigation.navigate(PagesEnum.SearchListContainer, {
      ...route.params,
      sorts: value,
    })
    const params = {
      query: searchKeyword || '',
      filters: filters || '',
      sorts: value,
      page: 1,
    }

    setPage(1)
    dispatch(spiritActions.clearSearchList())
    getSearchSpiritsRequest(removeEmptyKeys(params))
  }

  const handleGoFilter = useCallback(() => {
    navigation.navigate(PagesEnum.MainFilterModal, {
      ...route.params,
      redirectTo: PagesEnum.SearchListContainer,
    })
  }, [navigation, route.params])

  const handleClickSearchBrand = (brandName: string) => {
    if (brandsData.length) {
      const brandKey = brandsData.filter(
        item => item.zhName === brandName || item.enName === brandName,
      )[0].i18nKey
      navigation.setParams({ brand: brandKey, q: undefined })
      setSearchKeyword('')
    }
  }

  const renderCardList = ({ item }: any) => {
    const {
      title,
      id,
      imageUrl,
      page: itemPage,
      selectedRow,
      ratings,
      titleUrlSlug,
      brand,
      requestId,
      marketPrice,
      flavors,
    } = item
    return (
      <SpiritCard
        key={id}
        id={id}
        onClick={() =>
          handleClickCard(id, titleUrlSlug, itemPage, selectedRow, requestId)
        }
        imageUrl={imageUrl}
        title={title}
        rating={ratings?.average}
        ratingCount={ratings?.count}
        brand={brand}
        marketPrice={marketPrice}
        flavors={flavors?.topN}
        showPrice={!!marketPrice}
        showFlavors={!!flavors?.topN?.length}
      />
    )
  }

  const renderListFooter = () => {
    if (isGetSearchSpiritsFetching) {
      return (
        <View>
          <ActivityIndicator size="large" color={Colors.primary} />
        </View>
      )
    }
    if (
      searchListData.data?.length &&
      searchListData.meta.currentPage === searchListData.meta.totalPages
    ) {
      return (
        <View style={[Layout.center, Gutters.largeTPadding]}>
          <Text
            style={[
              Fonts.weight500,
              Fonts.size16,
              Gutters.regularBMargin,
              { color: Colors.fontText.light.primary2 },
            ]}
          >
            找不到酒？
          </Text>
          <TouchableOpacity onPress={handleClickReportSpirit}>
            <Text
              style={[
                Fonts.weight500,
                Fonts.size16,
                Gutters.regularBMargin,
                { color: Colors.primary },
              ]}
            >
              協助新增品項
            </Text>
          </TouchableOpacity>
        </View>
      )
    }
  }

  const renderEmptyView = () => {
    if (isGetSearchSpiritsFetching || isGetSearchSpiritsLoading) {
      return (
        <View style={[Layout.colCenter, Layout.fullHeight]}>
          <ActivityIndicator size="large" color={Colors.primary} />
        </View>
      )
    }
    return (
      <View
        style={[Layout.colCenter, Layout.fullHeight, Gutters.regularHPadding]}
      >
        {showEmptyComponentCondition && (
          <>
            <Image
              source={Images.search_list_empty}
              resizeMode="contain"
              style={[Gutters.regularBMargin, styles.emptyIcon]}
            />
            <Text
              style={[
                Fonts.weight700,
                Fonts.size16,
                Gutters.tinyBMargin,
                { color: Colors.white },
              ]}
            >
              抱歉，找不到相似的結果
            </Text>
            <Text
              style={[
                Fonts.weight400,
                Fonts.size14,
                Gutters.regularBMargin,
                { color: Colors.secondaryText },
              ]}
            >
              請嘗試搜尋其他烈酒
            </Text>
            <TouchableOpacity onPress={handleClickReportSpirit}>
              <Text
                style={[
                  Fonts.weight500,
                  Fonts.size16,
                  Gutters.regularBMargin,
                  { color: Colors.primary },
                ]}
              >
                協助新增品項
              </Text>
            </TouchableOpacity>
          </>
        )}
      </View>
    )
  }

  // 文字搜尋的 title
  const renderTextSearchTitle = () => {
    return (
      <Appbar.Header
        style={[
          Layout.fullWidth,
          Layout.rowCenter,
          Gutters.regularHPadding,
          {
            backgroundColor: Colors.background.default,
          },
        ]}
      >
        <TouchableOpacity onPress={handleGoBack}>
          <Image
            style={[Layout.iconSize24]}
            source={Images.arrowLeft}
            resizeMode="contain"
          />
        </TouchableOpacity>
        <View
          style={[
            Layout.row,
            Gutters.smallHPadding,
            styles.searchInputContainer,
          ]}
        >
          <TextInput
            ref={textInputRef}
            style={[styles.searchInput, { color: Colors.white }]}
            value={searchKeyword}
            placeholder="搜尋今天喝的烈酒，例如 Jimbeam"
            placeholderTextColor={Colors.gray}
            returnKeyType="search"
            returnKeyLabel="search"
            onChangeText={text => setSearchKeyword(text)}
            onSubmitEditing={fetchData}
          />
          {!!searchKeyword?.length && textInputRef?.current?.isFocused() && (
            <TouchableOpacity onPress={handleClearSearchKeyword}>
              <Image
                source={Images.close}
                style={Layout.iconSize24}
                resizeMode="contain"
              />
            </TouchableOpacity>
          )}
        </View>
      </Appbar.Header>
    )
  }

  // 篩選列
  const renderFilterSorterContainer = () => {
    return (
      <View
        style={[
          Layout.row,
          Layout.alignItemsCenter,
          Gutters.regularHPadding,
          Gutters.regularBPadding,
          { backgroundColor: Colors.background.default },
        ]}
      >
        <TouchableOpacity style={[styles.sortButton]} onPress={showModal}>
          <Image
            source={Images.search_filter_sort_icon}
            style={[Layout.iconSize24, Gutters.tinyRMargin]}
          />
          <Text
            style={[
              Fonts.weight500,
              Fonts.size14,
              { color: Colors.fontText.light.primary2 },
            ]}
          >
            {sortValue.label}
          </Text>
        </TouchableOpacity>
        <TouchableOpacity
          style={[
            styles.sortButton,
            {
              backgroundColor:
                convertFilterItemNumber('all') === 0
                  ? Colors.background.default
                  : Colors.primary,
            },
          ]}
          onPress={handleGoFilter}
        >
          <Image
            source={
              convertFilterItemNumber('all') === 0
                ? Images.search_filter_filter_icon
                : Images.search_filter_filter_black_icon
            }
            style={[Layout.iconSize24, Gutters.tinyRMargin]}
          />
          <Text
            style={[
              Fonts.weight500,
              Fonts.size14,
              {
                color:
                  convertFilterItemNumber('all') === 0
                    ? Colors.fontText.light.primary2
                    : Colors.fontText.dark.primary2,
              },
            ]}
          >
            篩選
          </Text>
        </TouchableOpacity>
        <TouchableOpacity
          style={[
            styles.sortButton,
            {
              backgroundColor:
                convertFilterItemNumber('brand') === 0
                  ? Colors.background.default
                  : Colors.primary,
            },
          ]}
          onPress={() => {
            navigation.navigate(PagesEnum.BrandFilterModal, {
              ...route.params,
              redirectTo: PagesEnum.SearchListContainer,
            })
          }}
        >
          <Text
            style={[
              Fonts.weight500,
              Fonts.size14,
              Gutters.tinyRMargin,
              {
                color:
                  convertFilterItemNumber('brand') === 0
                    ? Colors.fontText.light.primary2
                    : Colors.fontText.dark.primary2,
              },
            ]}
          >
            品牌
          </Text>
          <Image
            source={
              convertFilterItemNumber('brand') === 0
                ? Images.arrowDown
                : Images.arrowDown_black
            }
            style={[Layout.iconSize24]}
          />
        </TouchableOpacity>
      </View>
    )
  }

  return (
    <View
      style={[
        Layout.fill,
        {
          backgroundColor: Colors.background.default,
          height: Dimensions.get('window').height,
        },
      ]}
    >
      <FadeInView duration={500} style={Layout.fill}>
        {renderTextSearchTitle()}
        {isGetSearchSpiritsSuccess && renderFilterSorterContainer()}
        <View style={[Layout.fill]}>
          {isGetSearchSpiritsLoading ? (
            <View style={[Layout.fill, Layout.center]}>
              <ActivityIndicator size="large" color={Colors.primary} />
            </View>
          ) : (
            <View
              style={[Layout.fill, { height: Dimensions.get('window').height }]}
            >
              <FlatList
                ref={flatListRef}
                data={searchListData.data || []}
                contentContainerStyle={[Layout.fill]}
                keyExtractor={item => item.id.toString()}
                renderItem={renderCardList}
                onScroll={() => Keyboard?.dismiss()}
                refreshing
                scrollEnabled={!productImageLightBox?.visible}
                maxToRenderPerBatch={10}
                onEndReached={() => {
                  handleFetchMoreData()
                }}
                onEndReachedThreshold={0.1}
                getItemLayout={(data, index) => {
                  return {
                    length: SpiritCardHeight,
                    offset: SpiritCardHeight * index,
                    index,
                  }
                }}
                ListFooterComponent={renderListFooter}
                ListEmptyComponent={renderEmptyView}
                ListHeaderComponent={() => {
                  const data =
                    advertiseData?.data?.[
                      AdvertiseSpaceEnum.TEXT_SEARCH_TITLE
                    ]?.[searchListTitleAdvertiseIndex]
                  if (data && searchListData.data.length > 0) {
                    return (
                      <>
                        <View style={[Gutters.tinyBMargin]}>
                          <AdvertiseComponent
                            data={data}
                            space={AdvertiseSpaceEnum.TEXT_SEARCH_TITLE}
                            customCardStyle={{
                              backgroundColor: Colors.background.default,
                            }}
                          />
                        </View>
                        {searchBrandData.length > 0 &&
                          convertFilterItemNumber('brand') === 0 && (
                            <View
                              style={[
                                Layout.fullWidth,
                                Gutters.smallVPadding,
                                Gutters.regularHPadding,
                                Gutters.tinyBMargin,
                                { backgroundColor: Colors.background.surface },
                              ]}
                            >
                              <Text
                                style={[
                                  Fonts.size12,
                                  Fonts.weight500,
                                  Gutters.smallBMargin,
                                  { color: Colors.fontText.light.primary2 },
                                ]}
                              >
                                建議品牌
                              </Text>
                              {searchBrandData.length > 0 &&
                                searchBrandData.map(brandName => {
                                  if (brandName) {
                                    return (
                                      <BrandItem
                                        key={brandName}
                                        brandName={brandName}
                                        onPress={handleClickSearchBrand}
                                      />
                                    )
                                  } else {
                                    return null
                                  }
                                })}
                            </View>
                          )}
                      </>
                    )
                  }
                }}
                ItemSeparatorComponent={({ leadingItem }) => {
                  return (
                    <AdvertiseList
                      leadingItem={leadingItem}
                      listData={searchListData.data}
                      adData={
                        advertiseData?.data?.[
                          AdvertiseSpaceEnum.TEXT_SEARCH_LIST
                        ] || []
                      }
                      space={AdvertiseSpaceEnum.TEXT_SEARCH_LIST}
                    />
                  )
                }}
              />
            </View>
          )}
        </View>
      </FadeInView>
      <View>
        <WarningSignComponent bottomSpace />
      </View>
      <DownLoadSign />
      {visible && (
        <SortModal
          visible={visible}
          hideModal={hideModal}
          onSort={handleSort}
          sortValue={sortValue}
          setSortValue={setSortValue}
          handleSortSubmit={handleSortSubmit}
        />
      )}
    </View>
  )
}

const getStyle = (searchKeyword: string, textInputRef: any) =>
  StyleSheet.create({
    emptyIcon: {
      width: 150,
      height: 150,
    },
    searchInputContainer: {
      width: '100%',
      height: 40,
      backgroundColor: Colors.background.default,
      borderRadius: 8,
      borderColor:
        searchKeyword?.length && textInputRef?.current?.isFocused()
          ? Colors.primary
          : 'rgba(255, 255, 255, 0.6)',
      borderWidth: 1,
      alignItems: 'center',
    },
    searchInput: {
      width: '90%',
      marginLeft: 4,
      outlineWidth: 0,
      outlineColor: 'transparent',
      outlineStyle: 'none',
    },
    arrowLeftIcon: {
      width: 24,
      height: 24,
      paddingLeft: 50,
    },
    sortButton: {
      flexDirection: 'row',
      alignItems: 'center',
      borderRadius: 8,
      borderWidth: 1,
      borderColor: Colors.background.top,
      padding: 8,
      marginRight: 16,
    },
    sortItemNumber: {
      width: 18,
      height: 18,
      backgroundColor: Colors.primary,
      borderRadius: 18,
      justifyContent: 'center',
      alignItems: 'center',
      marginLeft: 8,
    },
  })

export default SearchListContainer
