import { ThemeVariables } from './theme'

export default function ({ }: ThemeVariables) {
  return {
    // 共用
    logo: require('@/Assets/Images/logo.svg'),
    logoSlogan: require('@/Assets/Images/logoSlogan.png'),
    favicon: require('@/Assets/Images/favicon.ico'),
    app_icon: require('@/Assets/Images/app_icon.png'),
    empty: require('@/Assets/Images/Empty.png'),
    defaultAvatar: require('@/Assets/Images/defaultAvatar.png'),
    desktop_bg: require('@/Assets/Images/desktop-bg.webp'),
    warningSign: require('@/Assets/Images/warningSign.png'),
    warningIcon: require('@/Assets/Images/warningIcon.svg'),
    close: require('@/Assets/Images/closeIcon.svg'),
    arrowLeft: require('@/Assets/Images/arrowLeft.svg'),
    arrowRight: require('@/Assets/Images/arrowRight.svg'),
    arrowDown: require('@/Assets/Images/arrowDown.png'),
    arrowDown_black: require('@/Assets/Images/arrowDown-black.png'),
    notifications: require('@/Assets/Images/notifications.svg'),
    share_bg_white: require('@/Assets/Images/share-bg-white.svg'),
    share: require('@/Assets/Images/share.svg'),
    share_gray: require('@/Assets/Images/share-gray.svg'),
    share_black: require('@/Assets/Images/share_black.svg'),
    collect: require('@/Assets/Images/collect.svg'),
    collected: require('@/Assets/Images/collected.svg'),
    eye_off: require('@/Assets/Images/eye-off.svg'),
    eye_open: require('@/Assets/Images/eye-outline.svg'),
    more: require('@/Assets/Images/more.svg'),
    search: require('@/Assets/Images/search.svg'),
    camera: require('@/Assets/Images/camera.svg'),
    camera_white: require('@/Assets/Images/camera-white.png'),
    info: require('@/Assets/Images/info.png'),
    star: require('@/Assets/Images/star.png'),
    // 元件 components
    bottom_dialog_login_leave: require('@/Assets/Images/Components/BottomDialog/Login/loginLeave.svg'),
    components_snackbar_success: require('@/Assets/Images/Components/Snackbar/success.svg'),
    components_snackbar_warning: require('@/Assets/Images/Components/Snackbar/warning.svg'),
    components_spirit_rating_star: require('@/Assets/Images/Components/SpiritCardWithContent/ratingStar.svg'),
    components_spirit_collect: require('@/Assets/Images/Components/SpiritCardWithContent/collect.svg'),
    components_spirit_collected: require('@/Assets/Images/Components/SpiritCardWithContent/collected.svg'),
    components_spirit_delete: require('@/Assets/Images/Components/SpiritCardWithContent/delete.svg'),
    components_spirit_edit: require('@/Assets/Images/Components/SpiritCardWithContent/edit.svg'),
    // start up
    // main tab
    mainTabProfile: require('@/Assets/Images/MainTab/profile.svg'),
    mainTabProfileActive: require('@/Assets/Images/MainTab/profile-active.svg'),
    mainTabSearch: require('@/Assets/Images/MainTab/search.svg'),
    mainTabSearchActive: require('@/Assets/Images/MainTab/search-active.svg'),
    mainTabCamera: require('@/Assets/Images/MainTab/camera.svg'),
    mainTabHome: require('@/Assets/Images/MainTab/home.svg'),
    mainTabHomeActive: require('@/Assets/Images/MainTab/home-active.svg'),
    // camera
    camera_album: require('@/Assets/Images/Camera/album.svg'),
    camera_reFresh: require('@/Assets/Images/re-fresh.svg'),
    camera_reFresh_black: require('@/Assets/Images/re-fresh-black.svg'),
    camera_takePicCamera: require('@/Assets/Images/Camera/takePicCamera.svg'),
    camera_description: require('@/Assets/Images/Camera/description.svg'),
    camera_flash: require('@/Assets/Images/Camera/flash.svg'),
    camera_scan_label: require('@/Assets/Images/Camera/scanLabel.svg'),
    camera_mask_light: require('@/Assets/Images/Camera/Camera_mask_light.svg'),
    camera_mask_dark: require('@/Assets/Images/Camera/Camera_mask_dark.svg'),
    camera_close: require('@/Assets/Images/Camera/close.svg'),
    camera_gallery: require('@/Assets/Images/Camera/gallery.svg'),
    camera_example: require('@/Assets/Images/Camera/example.png'),
    camera_report_spirit_example_mark: require('@/Assets/Images/Camera/ReportSpiritContainer/exampleMark.png'),
    // scan result
    scan_result_done: require('@/Assets/Images/SearchResult/done.svg'),
    scan_result_top_background: require('@/Assets/Images/ScanResult/topBackground.png'),
    scan_result_best_match: require('@/Containers/CameraContainer/assets/bestMatch.png'),
    scan_loading_border: require('@/Assets/Images/ScanLoading/imageBorder.png'),
    scan_loading_empty_product: require('@/Assets/Images/ScanLoading/emptyProduct.png'),
    // product detail
    product_detail_thumb_up: require('@/Assets/Images/ProductDetail/thumb-up.svg'),
    product_detail_thumb_up_fill: require('@/Assets/Images/ProductDetail/thumb-up-fill.svg'),
    product_detail_comment: require('@/Assets/Images/ProductDetail/comment.svg'),
    product_detail_hint: require('@/Assets/Images/ProductDetail/hint.svg'),
    product_detail_share: require('@/Assets/Images/ProductDetail/share.svg'),
    product_detail_add_note: require('@/Assets/Images/ProductDetail/addNote.svg'),
    product_detail_edit_note: require('@/Assets/Images/ProductDetail/editNote.svg'),
    product_detail_coupon_icon: require('@/Assets/Images/ProductDetail/couponIcon.png'),
    product_detail_coupon_background: require('@/Assets/Images/ProductDetail/couponBackground.png'),
    // 指定產地圖
    product_detail_top_cognacRegion: require('@/Assets/Images/ProductDetail/CognacRegion.webp'),
    product_detail_top_speyside: require('@/Assets/Images/ProductDetail/Speyside.webp'),
    product_detail_top_lowland: require('@/Assets/Images/ProductDetail/Lowland.webp'),
    product_detail_top_whiskyCampbeltown: require('@/Assets/Images/ProductDetail/whiskyCampbeltown.webp'),
    product_detail_top_whiskyHighlands: require('@/Assets/Images/ProductDetail/whiskyHighlands.webp'),
    product_detail_top_whiskyIslay: require('@/Assets/Images/ProductDetail/whiskyIslay.webp'),
    // 隨機產地圖
    product_detail_random1: require('@/Assets/Images/ProductDetail/random1.png'),
    product_detail_random2: require('@/Assets/Images/ProductDetail/random2.png'),
    product_detail_random3: require('@/Assets/Images/ProductDetail/random3.png'),
    product_detail_random4: require('@/Assets/Images/ProductDetail/random4.png'),
    product_detail_random5: require('@/Assets/Images/ProductDetail/random5.png'),
    product_detail_random6: require('@/Assets/Images/ProductDetail/random6.png'),
    // 風味標籤
    product_detail_flavor_icon_cereal: require('@/Assets/Images/ProductDetail/flavorIcon/flavorIcon_cereal.svg'),
    product_detail_flavor_icon_feinty: require('@/Assets/Images/ProductDetail/flavorIcon/flavorIcon_feinty.svg'),
    product_detail_flavor_icon_fruity: require('@/Assets/Images/ProductDetail/flavorIcon/flavorIcon_fruity.svg'),
    product_detail_flavor_icon_floral: require('@/Assets/Images/ProductDetail/flavorIcon/flavorIcon_floral.svg'),
    product_detail_flavor_icon_peaty: require('@/Assets/Images/ProductDetail/flavorIcon/flavorIcon_peaty.svg'),
    product_detail_flavor_icon_sulphury: require('@/Assets/Images/ProductDetail/flavorIcon/flavorIcon_sulphury.svg'),
    product_detail_flavor_icon_winey: require('@/Assets/Images/ProductDetail/flavorIcon/flavorIcon_winey.svg'),
    product_detail_flavor_icon_woody: require('@/Assets/Images/ProductDetail/flavorIcon/flavorIcon_woody.svg'),
    product_detail_flavor_icon_other: require('@/Assets/Images/ProductDetail/flavorIcon/flavorIcon_other.svg'),
    // profile
    profile_profile: require('@/Assets/Images/Profile/profile.webp'),
    profile_spiritIcon: require('@/Assets/Images/Profile/spiritIcon.svg'),
    profile_inviteFriendsIcon: require('@/Assets/Images/Profile/inviteFriendsIcon.svg'),
    profile_rateIcon: require('@/Assets/Images/Profile/rateIcon.svg'),
    profile_wishlistIcon: require('@/Assets/Images/Profile/wishlistIcon.svg'),
    profile_settingIcon: require('@/Assets/Images/Profile/settingIcon.svg'),
    profile_rightArrowIcon: require('@/Assets/Images/Profile/rightArrowIcon.svg'),
    profile_historyIcon: require('@/Assets/Images/Profile/historyIcon.svg'),
    profile_addLiquorIcon: require('@/Assets/Images/Profile/addLiquorIcon.svg'),
    profile_couponIcon: require('@/Assets/Images/Profile/coupon.svg'),
    profile_achievementIcon: require('@/Assets/Images/Profile/achievementIcon.svg'),
    profile_more: require('@/Assets/Images/Profile/more.svg'),
    profile_reviewFeedbackIcon: require('@/Assets/Images/Profile/review.png'),
    profile_new_tag_icon: require('@/Assets/Images/Profile/new-tag.png'),
    // profile main
    profile_main_achievement: require('@/Assets/Images/ProfileMain/achievement.svg'),
    profile_main_notesEmpty: require('@/Assets/Images/ProfileMain/notesEmpty.svg'),
    profile_main_wishlistEmpty: require('@/Assets/Images/ProfileMain/wishlistEmpty.svg'),
    profile_main_info: require('@/Assets/Images/ProfileMain/info.svg'),
    profile_main_edit: require('@/Assets/Images/ProfileMain/edit.svg'),
    profile_main_facebook_icon: require('@/Containers/ProfileDetail/assets/facebook.png'),
    profile_main_youtube_icon: require('@/Containers/ProfileDetail/assets/youtube.png'),
    profile_main_instagram_icon: require('@/Containers/ProfileDetail/assets/instagram.png'),
    profile_main_threads_icon: require('@/Containers/ProfileDetail/assets/threads.png'),
    profile_main_website_icon: require('@/Containers/ProfileDetail/assets/website.png'),
    // profile detail
    profile_detail_setting: require('@/Assets/Images/ProfileDetail/ProfileDetail/setting.svg'),
    profile_detail_policy: require('@/Assets/Images/ProfileDetail/ProfileDetail/policy.svg'),
    profile_detail_releaseNote: require('@/Assets/Images/ProfileDetail/ProfileDetail/releaseNote.svg'),
    // profile upload spirits detail
    profile_upload_spirits_detail_uploadImage: require('@/Assets/Images/Profile/UploadSpiritsDetail/uploadImage.svg'),
    // invite friend
    invite_friend_invite: require('@/Assets/Images/InviteFriend/invitation_icon.svg'),
    invite_friend_invite_copy: require('@/Assets/Images/InviteFriend/copy.svg'),
    // invite friend list
    invite_friend_list_empty: require('@/Assets/Images/InviteFriendList/empty.svg'),
    // history
    history_search_no_result: require('@/Assets/Images/HistoryContainer/search_no_result.png'),
    history_checked: require('@/Assets/Images/HistoryContainer/checked.png'),
    history_example_mark: require('@/Assets/Images/HistoryContainer/exampleMark.png'),
    history_report: require('@/Assets/Images/HistoryContainer/report.png'),
    // upload spirits
    upload_spirits_empty: require('@/Assets/Images/UploadSpiritsContainer/empty.png'),
    // rating and comment
    rating_comment_arrow_down: require('@/Assets/Images/RatingAndComment/arrowDown.svg'),
    rating_comment_star: require('@/Assets/Images/RatingAndComment/star.svg'),
    rating_comment_add_circle: require('@/Assets/Images/RatingAndComment/addCircle.svg'),
    rating_comment_close: require('@/Assets/Images/RatingAndComment/close.svg'),
    rating_comment_add_flavor: require('@/Assets/Images/RatingAndComment/addFlavor.svg'),
    rating_comment_added_flavor: require('@/Assets/Images/RatingAndComment/addedFlavor.svg'),
    rating_comment_empty_cup: require('@/Assets/Images/RatingAndComment/empty-cup.svg'),
    rating_comment_cup0: require('@/Assets/Images/RatingAndComment/cup0.svg'),
    rating_comment_cup1: require('@/Assets/Images/RatingAndComment/cup1.svg'),
    rating_comment_cup2: require('@/Assets/Images/RatingAndComment/cup2.svg'),
    rating_comment_cup3: require('@/Assets/Images/RatingAndComment/cup3.svg'),
    rating_comment_cup4: require('@/Assets/Images/RatingAndComment/cup4.svg'),
    rating_comment_cup5: require('@/Assets/Images/RatingAndComment/cup5.svg'),
    // home
    home_search: require('@/Assets/Images/Home/search.svg'),
    home_empty: require('@/Assets/Images/Home/empty.png'),
    home_category_banner: require('@/Assets/Images/Home/category_banner.png'),
    home_category_banner_big: require('@/Assets/Images/Home/category_banner_big.png'),
    home_ai_chat_banner: require('@/Assets/Images/Home/ai_chat_banner.png'),
    home_new_icon: require('@/Assets/Images/Home/new_icon.png'),

    home_whisky_icon: require('@/Assets/Images/Home/whiskey_icon.svg'),
    home_vodka_icon: require('@/Assets/Images/Home/vodka_icon.svg'),
    home_rum_icon: require('@/Assets/Images/Home/rum_icon.svg'),
    home_vermouth_icon: require('@/Assets/Images/Home/vermouth_icon.svg'),
    home_tequila_icon: require('@/Assets/Images/Home/tequila_icon.svg'),
    home_gin_icon: require('@/Assets/Images/Home/gin_icon.svg'),
    home_brandy_icon: require('@/Assets/Images/Home/brandy_icon.svg'),
    home_absinthe_icon: require('@/Assets/Images/Home/absinthe_icon.svg'),
    home_vermouth_block: require('@/Assets/Images/Home/vermouth_block.webp'),
    home_absinthe_block: require('@/Assets/Images/Home/absinthe_block.webp'),
    home_yellow_background: require('@/Assets/Images/Home/yellow_background.png'),
    home_red_background: require('@/Assets/Images/Home/red_background.png'),
    home_green_background: require('@/Assets/Images/Home/green_background.png'),
    home_blue_background: require('@/Assets/Images/Home/blue_background.png'),
    home_orange_background: require('@/Assets/Images/Home/orange_background.png'),
    home_gold_background: require('@/Assets/Images/Home/gold_background.png'),
    home_black_background: require('@/Assets/Images/Home/black_background.png'),
    // search list
    search_list_empty: require('@/Assets/Images/SearchList/empty.svg'),
    search_list_suggest_brand: require('@/Containers/Search/assets/suggest-brand.png'),
    search_filter_range_thumb: require('@/Containers/Search/assets/thumb.png'),
    search_filter_rating_star: require('@/Containers/Search/assets/rating-star.svg'),
    search_filter_sort_icon: require('@/Containers/Search/assets/sortIcon.svg'),
    search_filter_filter_icon: require('@/Containers/Search/assets/filterIcon.svg'),
    search_filter_filter_black_icon: require('@/Containers/Search/assets/filterIcon-black.png'),
    search_landing_category_banner: require('@/Containers/Search/assets/category-banner.png'),
    search_landing_banner: require('@/Containers/Search/assets/banner.png'),
    search_landing_tab_brand: require('@/Containers/Search/assets/tab-brand.png'),
    search_landing_tab_flavor: require('@/Containers/Search/assets/tab-flavor.png'),
    search_landing_tab_pairing: require('@/Containers/Search/assets/tab-pairing.png'),
    search_landing_tab_country: require('@/Containers/Search/assets/tab-country.png'),
    search_landing_tab_category: require('@/Containers/Search/assets/tab-category.png'),
    search_landing_bottle_icon: require('@/Containers/Search/assets/bottle.png'),
    // my spirit
    my_spirit_empty: require('@/Assets/Images/MySpirit/empty.svg'),
    // wishlist
    wishlist_empty: require('@/Assets/Images/Wishlist/empty.svg'),
    // login
    login_main_FB: require('@/Assets/Images/LoginMain/FB.svg'),
    login_main_Google: require('@/Assets/Images/LoginMain/Google-Button.svg'),
    login_main_Apple: require('@/Assets/Images/LoginMain/Apple-Button.svg'),
    login_main_Mail: require('@/Assets/Images/LoginMain/mail.svg'),
    login_FB_square: require('@/Assets/Images/Login/facebook_square_btn.png'),
    login_Google_square: require('@/Assets/Images/Login/google_square_btn.png'),
    login_Apple_square: require('@/Assets/Images/Login/apple_square_btn.png'),
    login_FB: require('@/Assets/Images/Login/FB.svg'),
    // forgot password
    forgot_password_success: require('@/Assets/Images/ForgotPassword/success.svg'),
    forgot_password_error_icon: require('@/Assets/Images/ForgotPassword/errorIcon.svg'),
    // register
    register_success: require('@/Assets/Images/Register/success.svg'),
    register_check_icon: require('@/Assets/Images/Register/checkIcon.png'),
    register_checked_icon: require('@/Assets/Images/Register/checkedIcon.png'),
    // email basic info
    email_basic_info: require('@/Assets/Images/EmailBasicInfo/Empty_illustration icon.svg'),
    // review note
    review_note_send: require('@/Assets/Images/ReviewNote/send_filled.svg'),
    review_note_cup: require('@/Assets/Images/ReviewNote/whiskeyCup.svg'),
    // review detail
    review_detail_photo: require('@/Assets/Images/ReviewDetail/photo.svg'),
    review_detail_page_arrow: require('@/Assets/Images/ReviewDetail/page_arrow.svg'),
    review_detail_success_icon: require('@/Assets/Images/ReviewDetail/successIcon.svg'),
    // comment modal
    comment_modal_my_template: require('@/Assets/Images/CommentModal/my-template.svg'),
    // 風味
    flavor_cereal: require('@/Assets/Images/Flavor/icon_cereal.svg'),
    flavor_feinty: require('@/Assets/Images/Flavor/icon_feinty.svg'),
    flavor_fruity: require('@/Assets/Images/Flavor/icon_fruity.svg'),
    flavor_floral: require('@/Assets/Images/Flavor/icon_floral.svg'),
    flavor_peaty: require('@/Assets/Images/Flavor/icon_peaty.svg'),
    flavor_sulphury: require('@/Assets/Images/Flavor/icon_sulphury.svg'),
    flavor_winey: require('@/Assets/Images/Flavor/icon_winey.svg'),
    flavor_woody: require('@/Assets/Images/Flavor/icon_woody.svg'),
    // glass 酒杯
    glass_brandy_glass: require('@/Assets/Images/Glass/brandy-glass.png'),
    glass_champagne_flute: require('@/Assets/Images/Glass/champagne-flute.png'),
    glass_champagne_glass: require('@/Assets/Images/Glass/champagne-glass.png'),
    glass_collins_glass: require('@/Assets/Images/Glass/collins-glass.png'),
    glass_glencairn_glass: require('@/Assets/Images/Glass/glencairn-glass.png'),
    glass_highball_glass: require('@/Assets/Images/Glass/highball-glass.png'),
    glass_martini_glass: require('@/Assets/Images/Glass/martini-glass.png'),
    glass_old_fashioned_glass: require('@/Assets/Images/Glass/old-fashioned-glass.png'),
    glass_shot_glass: require('@/Assets/Images/Glass/shot-glass.png'),
    glass_tulip_shaped_glass: require('@/Assets/Images/Glass/tulip-shaped-glass.png'),
    glass_blenders_glass: require('@/Assets/Images/Glass/blenders-glass.png'),
    glass_fish_tank_glass: require('@/Assets/Images/Glass/fish-tank-glass.png'),
    glass_iso_glass: require('@/Assets/Images/Glass/iso-glass.png'),
    glass_red_wine_glass: require('@/Assets/Images/Glass/red-wine-glass.png'),
    glass_white_wine_glass: require('@/Assets/Images/Glass/white-wine-glass.png'),
    // AI 聊天室烈酒答人
    ai_chat_icon: require('@/Containers/AIChatRoom/assets/AI_chat_icon.png'),
    ai_chat_avatar: require('@/Containers/AIChatRoom/assets/ai_chat_avatar.png'),
    beta_icon: require('@/Containers/AIChatRoom/assets/beta_icon.png'),
    hint_icon: require('@/Containers/AIChatRoom/assets/hint_icon.png'),
    // coupon
    coupon_list_icon: require('@/Assets/Images/Coupon/couponIcon.png'),
    coupon_list_disabled_icon: require('@/Assets/Images/Coupon/couponIconDisabled.png'),
    coupon_result_success_icon: require('@/Assets/Images/Coupon/successIcon.svg'),
    coupon_result_failed_icon: require('@/Assets/Images/Coupon/failed.png'),
  }
}
