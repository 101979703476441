import { api, prefixProxyEndpoint } from '@/Services/api'
import { couponActions } from '@/Store/Coupon'

import {
  GetSpiritCouponsRequest,
  GetSpiritCouponsResponse,
  PostCouponRequest,
  GetCouponRequest,
  GetCouponListResponse,
  GetCouponDetailRequest,
  GetCouponDetailResponse,
  RedeemCouponRequest,
  RedeemCouponResponse,
  GetCouponListByProgramIdRequest,
  GetCouponListByProgramIdResponse,
} from './loyaltyPrograms.type'

export const loyaltyProgramsApi = api.injectEndpoints({
  endpoints: build => ({
    // 在烈酒頁面取得特定烈酒優惠券
    getSpiritCoupons: build.query<
      GetSpiritCouponsResponse,
      GetSpiritCouponsRequest
    >({
      query: params => ({
        url: prefixProxyEndpoint('v1/loyalty-programs'),
        method: 'GET',
        params,
      }),
      providesTags: ['loyaltyPrograms'],
    }),
    // 在烈酒頁面取得特定優惠券
    getCoupon: build.mutation<void, PostCouponRequest>({
      query: body => ({
        url: prefixProxyEndpoint('v1/coupons'),
        method: 'POST',
        body,
      }),
      async onQueryStarted(props, { dispatch, queryFulfilled }) {
        const result = await queryFulfilled
        if (result) {
          dispatch(loyaltyProgramsApi.util.invalidateTags(['loyaltyPrograms']))
        }
      },
    }),
    // 取得優惠券列表
    getCouponList: build.query<GetCouponListResponse, GetCouponRequest>({
      query: params => ({
        url: prefixProxyEndpoint('v1/coupons'),
        method: 'GET',
        params: {
          ...params,
          perPage: 20,
        },
      }),
      providesTags: ['loyaltyPrograms'],
      async onQueryStarted(props, { dispatch, queryFulfilled }) {
        const result = await queryFulfilled
        if (result) {
          // 因為是列表資料，所以先存到 store 裏面
          dispatch(
            couponActions.setCouponList({
              status: props.status,
              data: result?.data,
            }),
          )
        }
      },
    }),
    // 取得優惠券詳情
    getCouponDetail: build.query<
      GetCouponDetailResponse,
      GetCouponDetailRequest
    >({
      query: params => ({
        url: prefixProxyEndpoint(`v1/coupons/${params.id}`),
        method: 'GET',
      }),
      providesTags: ['loyaltyPrograms'],
    }),
    // 核銷優惠券
    redeemCoupon: build.mutation<RedeemCouponResponse, RedeemCouponRequest>({
      query: body => ({
        url: prefixProxyEndpoint(`v1/coupons/${body.id}:redeem`),
        method: 'POST',
        body,
      }),
    }),
    // 透過 programId 取得優惠券列表
    getCouponListByProgramId: build.query<
      GetCouponListByProgramIdResponse,
      GetCouponListByProgramIdRequest
    >({
      query: body => ({
        url: prefixProxyEndpoint('v1/loyalty-programs:fetch-by-uid'),
        method: 'POST',
        body,
      }),
      providesTags: [{ type: 'loyaltyPrograms', id: 'i09-special-offer' }],
    }),
  }),
  overrideExisting: false,
})

loyaltyProgramsApi.enhanceEndpoints({
  addTagTypes: ['loyaltyPrograms'],
  endpoints: {},
})

export default loyaltyProgramsApi
