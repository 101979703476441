import { PagesEnum } from '@/Navigators/Application'
import { getRouteHistory } from '@/Navigators/utils'
import { createSlice, Dispatch } from '@reduxjs/toolkit'

export interface CampaignEventState {
  fromCampaignPage: boolean
}

const initialState: CampaignEventState = {
  fromCampaignPage: false,
}

const campaignEventSlice = createSlice({
  name: 'campaignEvent',
  initialState,
  reducers: {
    setFromCampaignPage: state => {
      state.fromCampaignPage = true
    },
    clearFromCampaignPage: state => {
      state.fromCampaignPage = false
    },
  },
})

export const campaignEventReducer = campaignEventSlice.reducer
export const campaignEventActions = campaignEventSlice.actions

const observerLastPageIsFromCampaignPage = (dispatch: Dispatch) => {
  const routeHistory = getRouteHistory() || []
  let lastPage = ''
  if (routeHistory?.length > 0) {
    lastPage = routeHistory[routeHistory.length - 1].name
  }
  if (lastPage === PagesEnum.CampaignSite) {
    dispatch(campaignEventActions.setFromCampaignPage())
    return
  }

  dispatch(campaignEventActions.clearFromCampaignPage())
}

export const campaignEventUtils = {
  observerLastPageIsFromCampaignPage,
}

export default campaignEventSlice.reducer
