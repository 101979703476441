export default {
  startup: {
    title: '您的全方位烈酒品飲助理',
  },
  mainTab: {
    home: '首頁',
    profile: '我的',
  },
  camera: {
    back: 'Back',
    startIdentify: 'Identify',
    scanLabelTitle1: '對準酒標',
    bottomDialogTitle: '請登入或註冊以繼續使用服務',
    bottomDialogButton1: '登入或註冊',
    bottomDialogButton2: '返回',
  },
  commentModal: {
    headerTitle: '編輯筆記',
    headerTemplateTitle: '模板',
    headerTemplateTitle_edit: '我的模板',
    flavorPlaceholder: '這支酒的香氣、口感、餘韻是什麼風味？最後來個總結吧！',
    templatePlaceholder: '輸入你的模板內容',
  },
  mySpirit: {
    title: '我的品飲筆記',
    add: 'Add Spirits',
    price: '${{price}}',
  },
  wishlist: {
    title: '願望清單',
    price: '${{price}}',
  },
  productDetails: {
    rated: 'Your rating',
    averagePrice: '參考價格',
    price: 'NTD ${{price}}',
    review: '我的品飲筆記',
    addReview: 'Add Your Review',
    description: '關於這支酒',
    detail: '酒品詳情',
    anchorTitle1: '我的品飲筆記',
    anchorTitle2: '關於這支酒',
    anchorTitle3: '風味與筆記',
    chipTitle1: '最新',
    hintTitle: '提示',
    hintContent1: '您可以從這裡開始撰寫您的品飲筆記',
    hintContent2: '紀錄收藏好酒，與其他好友分享心得',
  },
  profile: {
    welcome: '歡迎 {{name}}',
    inviteFriends: '邀請好友',
    mySpirit: '我的品飲筆記',
    wishlist: '願望清單',
    history: '辨識紀錄',
  },
  profileDetail: {
    setting: '設定',
    policy: '政策條款',
    releaseNote: '版本',
    logout: '登出',
  },
  ratingAndComment: {
    headerRight: '完成',
    addComment: '填寫你的品飲筆記',
    editComment: '編輯你的品飲筆記',
  },
  scanLoading: {
    done: '完成！',
    scanAgain: 'No result found, please scan again',
  },
  scanResult: {
    title: 'Results',
    bestMatch: '最佳配對',
    otherMatch: '相似結果',
  },
  reviewNoteList: {
    title: '全部品飲筆記',
    section1: '我的品飲筆記',
    section2: '其他品飲筆記',
  },
  reviewNote: {
    title: '筆記詳情',
    inputPlaceholder: '你有什麼想法呢？',
    comments: '留言',
  },
  components: {
    reviewCard: {
      share: '分享',
    },
    spiritCardWithContent: {
      editSpirit: '修改品飲筆記',
      removeSpirit: '刪除品飲筆記',
      removeSpiritDialogTitle: '刪除品飲筆記',
      removeSpiritDialogContent: '此行為無法復原，是否確認刪除品飲筆記？',
      removeSpiritDialogConfirm: '刪除品飲筆記',
      removeSpiritDialogCancel: '取消',
      shareSpirit: '分享品飲筆記',
      noRating: '暫無評分',
      noContent: '尚未撰寫品飲筆記',
      editNote: '修改品飲筆記',
      addNote: '新增品飲筆記',
      addWishlist: '願望清單',
    },
  },
  // 風味
  flavor: {
    winey: '酒香',
    sherried: '雪莉',
    nutty: '堅果',
    chocolate: '巧克力',
    oily: '油質',
    cereal: '穀物',
    'cooked-mash': '熟泥',
    'cooked-veg': '熟蔬菜',
    'malt-extract': '麥芽提取物',
    husky: '糠味',
    yeasty: '酵母味',
    fruity: '果味',
    citric: '柑橘',
    'fresh-fruit': '新鮮水果',
    'cooked-fruit': '熟水果',
    'dried-fruit': '乾果',
    solvent: '溶劑味',
    floral: '花香',
    fragrant: '芳香',
    'green-house': '溫室花香',
    leafy: '葉香',
    'hay-like': '乾草香',
    peaty: '泥煤味',
    medicinal: '藥味',
    smokey: '煙燻',
    kippery: '醃製海風味',
    mossy: '苔蘚味',
    feinty: '酒尾',
    honey: '蜂蜜',
    leathery: '皮革味',
    tobacco: '煙草味',
    sweaty: '汗味',
    plastic: '塑膠味',
    sulphury: '硫化物',
    vegitative: '蔬菜味',
    'coal-gas': '煤氣味',
    rubbery: '橡膠味',
    sandy: '沙土味',
    woody: '木質味',
    toasted: '烤味',
    vanilla: '香草',
    'old-wood': '舊木味',
    'new-wood': '新木味',
    others: '其他',
  },
  welcome: 'Welcome to React Native WenLiBaiMobile by TheCodingMachine',
  actions: {
    continue: 'Continue',
  },
  example: {
    helloUser: 'I am a fake user, my name is {{name}}',
    labels: {
      userId: 'Enter a user id',
    },
  },
}
