import { GetCouponListByProgramIdResponse } from '@/Services/modules/loyaltyPrograms/loyaltyPrograms.type'

export const USER_DEFINED_ID: string[] = [
  'i09_20250407_01',
  'i09_20250407_02',
  'i09_20250407_03',
  'i09_20250407_04',
  'i09_20250407_05',
  'i09_20250407_06',
  'i09_20250407_07',
  'i09_20250407_08',
  'i09_20250407_09',
  'i09_20250407_10',
  'i09_20250407_11',
  'i09_20250407_12',
  'i09_20250407_13',
  'i09_20250407_14',
  'i09_20250407_15',
  'i09_20250407_16',
  'i09_20250407_17',
  'i09_20250407_18',
  'i09_20250407_19',
  'i09_20250407_20',
  'i09_20250407_21',
  'i09_20250407_22',
  'i09_20250407_23',
  'i09_20250407_24',
  'i09_20250407_25',
  'i09_20250407_26',
  'i09_20250407_27',
  'i09_20250407_28',
  'i09_20250407_29',
  'i09_20250407_30',
  'i09_20250407_31',
  'i09_20250407_32',
  'i09_20250407_33',
  'i09_20250407_34',
  'i09_20250407_35',
  'i09_20250407_36',
  'i09_20250407_37',
  'i09_20250407_38',
  'i09_20250407_39',
  'i09_20250407_40',
  'i09_20250407_41',
  'i09_20250407_42',
  'i09_20250407_43',
  'i09_20250407_44',
]

const USER_DEFINED_ID_PRICE_MAP: Record<string, string> = {
  i09_20250407_01: '2,708',
  i09_20250407_02: '1,330',
  i09_20250407_03: '1,026',
  i09_20250407_04: '765',
  i09_20250407_05: '903',
  i09_20250407_06: '1,339',
  i09_20250407_07: '3,880',
  i09_20250407_08: '694',
  i09_20250407_09: '1,026',
  i09_20250407_10: '892',
  i09_20250407_11: '741',
  i09_20250407_12: '1,311',
  i09_20250407_13: '2,736',
  i09_20250407_14: '1,093',
  i09_20250407_15: '2,138',
  i09_20250407_16: '903',
  i09_20250407_17: '903',
  i09_20250407_18: '903',
  i09_20250407_19: '1,216',
  i09_20250407_20: '1,617',
  i09_20250407_21: '2,849',
  i09_20250407_22: '1,264',
  i09_20250407_23: '1,311',
  i09_20250407_24: '2,518',
  i09_20250407_25: '1,701',
  i09_20250407_26: '1,701',
  i09_20250407_27: '1,283',
  i09_20250407_28: '2,086',
  i09_20250407_29: '1,192',
  i09_20250407_30: '1,056',
  i09_20250407_31: '1,378',
  i09_20250407_32: '1,748',
  i09_20250407_33: '3,216',
  i09_20250407_34: '4,998',
  i09_20250407_35: '13,110',
  i09_20250407_36: '5,510',
  i09_20250407_37: '9,020',
  i09_20250407_38: '1,200',
  i09_20250407_39: '2,071',
  i09_20250407_40: '4,753',
  i09_20250407_41: '5,801',
  i09_20250407_42: '1,150',
  i09_20250407_43: '2,474',
  i09_20250407_44: '7,056',
}

const USER_DEFINED_ID_CATEGORY_MAP: Record<string, string[]> = {
  click_category_tab_01: [
    'i09_20250407_11',
    'i09_20250407_19',
    'i09_20250407_29',
    'i09_20250407_39',
  ],
  click_category_tab_02: [
    'i09_20250407_01',
    'i09_20250407_02',
    'i09_20250407_03',
    'i09_20250407_04',
    'i09_20250407_05',
    'i09_20250407_14',
    'i09_20250407_16',
    'i09_20250407_17',
    'i09_20250407_18',
    'i09_20250407_27',
    'i09_20250407_28',
    'i09_20250407_30',
    'i09_20250407_31',
    'i09_20250407_32',
    'i09_20250407_36',
  ],
  click_category_tab_03: [
    'i09_20250407_08',
    'i09_20250407_09',
    'i09_20250407_10',
    'i09_20250407_12',
    'i09_20250407_13',
  ],
  click_category_tab_04: [
    'i09_20250407_06',
    'i09_20250407_20',
    'i09_20250407_21',
    'i09_20250407_25',
    'i09_20250407_42',
    'i09_20250407_43',
    'i09_20250407_44',
  ],
  click_category_tab_05: [
    'i09_20250407_07',
    'i09_20250407_24',
    'i09_20250407_33',
    'i09_20250407_34',
    'i09_20250407_35',
    'i09_20250407_38',
    'i09_20250407_40',
    'i09_20250407_41',
  ],
  click_category_tab_06: [
    'i09_20250407_15',
    'i09_20250407_22',
    'i09_20250407_23',
    'i09_20250407_26',
    'i09_20250407_37',
  ],
}

// 六種分類 config
const categoryConfig: any[] = [
  {
    id: 'click_category_tab_01',
    name: '主打推薦',
    description: '｜威士忌之選・品味不凡｜',
    spirits: [],
  },
  {
    id: 'click_category_tab_02',
    name: '初學入門',
    description: '｜輕鬆品酩・從第一口開始愛上威士忌｜',
    spirits: [],
  },
  {
    id: 'click_category_tab_03',
    name: '泥煤煙燻款',
    description: '｜艾雷之魂・煙燻魅力極致綻放｜',
    spirits: [],
  },
  {
    id: 'click_category_tab_04',
    name: '雪莉桶控',
    description: '｜雪莉芳韻・馥郁甘甜的成熟誘惑｜',
    spirits: [],
  },
  {
    id: 'click_category_tab_05',
    name: '熟成經典款',
    description: '｜時間淬鍊・年份成就的非凡厚度｜',
    spirits: [],
  },
  {
    id: 'click_category_tab_06',
    name: '限定版精選',
    description: '｜臻稀限量・收藏家夢寐以求之選｜',
    spirits: [],
  },
]

const handleGetCategory = (userDefinedId: string) => {
  let category = ''
  for (const [categoryKey, categoryValue] of Object.entries(
    USER_DEFINED_ID_CATEGORY_MAP,
  )) {
    if (categoryValue.includes(userDefinedId)) {
      category = categoryKey
    }
  }
  return category
}

const handleResponse = (res: GetCouponListByProgramIdResponse) => {
  const { data } = res

  // 先重置 categoryConfig，避免資料重複
  categoryConfig.forEach(category => {
    category.spirits = []
  })

  data.forEach(item => {
    if (!item?.spirits.length) {
      return
    }
    const spirits = item.spirits[0]
    const userDefinedId = item.userDefinedId
    const category = handleGetCategory(userDefinedId)
    const specialOfferPrice = USER_DEFINED_ID_PRICE_MAP[userDefinedId]
    const categoryIndex = categoryConfig.findIndex(
      config => config.id === category,
    )
    categoryConfig[categoryIndex]?.spirits.push({
      ...spirits,
      collected: item.collected,
      specialOfferPrice,
      programId: item.id,
    })
  })
  console.log('categoryConfig', categoryConfig)
  return categoryConfig
}

const i09SpecialOfferFactory = {
  handleResponse,
}

export default i09SpecialOfferFactory
