import React, { useCallback } from 'react'
import {
  StatusBar,
  StyleSheet,
  View,
  Dimensions,
  Image,
  Text,
} from 'react-native'
import { createStackNavigator } from '@react-navigation/stack'
import { NavigationContainer } from '@react-navigation/native'
import {
  StartupContainer,
  EmailLoginContainer,
  LoginMainContainer,
  EmailRegisterContainer,
  EmailOTPContainer,
  EmailBasicInfoContainer,
  ReleaseNoteContainer,
  ProfileDetailContainer,
  NotFound,
  PrivacyContainer,
  SearchListContainer,
  ProductDetailContainer,
  ReviewNoteContainer,
  ReviewNoteListContainer,
  SocialMGMContainer,
  SocialOTPContainer,
  SocialBasicInfoContainer,
  ForgotPasswordContainer,
  ProfileMainContainer,
  UserAchievementContainer,
  AllAchievementContainer,
  FollowerContainer,
  SpiritsCategoriesContainer,
  MainFilterModal,
  BrandFilterModal,
  PairingFilterModal,
  CountryFilterModal,
  FlavorFilterModal,
  FilterSearchListContainer,
  CampaignSite,
  Universal,
  ImagesContainer,
} from '@/Containers'
import {
  useCheckLogin,
  useGetNestedSetI18n,
  useTheme,
  useUpdateSessions,
  useWindowSize,
} from '@/Hooks'
import MainNavigator, { MainPagesEnum } from './Main'
import { navigationRef } from './utils'
import { useDispatch, useSelector } from 'react-redux'
import { userApi } from '@/Services/modules/users'
import { changeLanguage, changeTheme } from '@/Store/Theme'
import Snackbar from '@/Components/Snackbar'
import { getGlobal } from '@/Store'
import { BottomDialog, ErrorBoundary, ImageLightBox } from '@/Components'
import { Provider } from 'react-native-paper'
import TimeAgo from 'javascript-time-ago'
import en from 'javascript-time-ago/locale/en'
import zh from 'javascript-time-ago/locale/zh-Hant'
import i18n from '@/Translations'
import { Colors } from '@/Theme/Variables'
import { Helmet } from 'react-helmet-async'
import { globalActions } from '@/Store/Global'
import { getConfig } from '@/Util/global'
import { SHOW_BOTTOM_WARNING_SIGN_WIDTH } from '@/Components/WarningSignComponent'
import {
  withAuthPages,
  withAuthPagesLinking,
  withAuthRedirectPages,
} from './withAuthPages'
import useIdleMins from '@/Hooks/useIdleMins'

// 添增 TimeAge 的語言
TimeAgo.addDefaultLocale(zh)
TimeAgo.addLocale(en)

const Stack = createStackNavigator()

export enum PagesEnum {
  Main = 'Main',
  LoginMainContainer = 'LoginMainContainer',
  EmailLoginContainer = 'EmailLoginContainer',
  EmailRegisterContainer = 'EmailRegisterContainer',
  EmailOTPContainer = 'EmailOTPContainer',
  EmailBasicInfoContainer = 'EmailBasicInfoContainer',
  SocialMGMContainer = 'SocialMGMContainer',
  SocialOTPContainer = 'SocialOTPContainer',
  SocialBasicInfoContainer = 'SocialBasicInfoContainer',
  ForgotPasswordContainer = 'ForgotPasswordContainer',
  ReleaseNoteContainer = 'ReleaseNoteContainer',
  ProfileDetailContainer = 'ProfileDetailContainer',
  Privacy = 'Privacy',
  SearchListContainer = 'SearchListContainer',
  NotFound = 'NotFound',
  ProductDetail = 'ProductDetail',
  ReviewNote = 'ReviewNote',
  ReviewNoteList = 'ReviewNoteList',
  AllAchievementContainer = 'AllAchievementContainer',
  ProfileMainContainer = 'ProfileMainContainer',
  FollowerContainer = 'FollowerContainer',
  SpiritsCategoriesContainer = 'SpiritsCategoriesContainer',
  UserAchievementContainer = 'UserAchievementContainer',
  MainFilterModal = 'MainFilterModal',
  BrandFilterModal = 'BrandFilterModal',
  PairingFilterModal = 'PairingFilterModal',
  CountryFilterModal = 'CountryFilterModal',
  FlavorFilterModal = 'FlavorFilterModal',
  FilterSearchListContainer = 'FilterSearchListContainer',
  CampaignSite = 'CampaignSite',
  Universal = 'universal',
  ImagesContainer = 'ImagesContainer',
}

const linking = {
  prefixes: [],
  config: {
    screens: {
      Startup: 'Startup',
      [PagesEnum.NotFound]: '*',
      Main: {
        screens: {
          [MainPagesEnum.Profile]: 'profile',
          [MainPagesEnum.Home]: 'Home',
          [MainPagesEnum.Camera]: 'Camera',
        },
      },
      [PagesEnum.ProfileMainContainer]: 'profile/:id',
      [PagesEnum.ProductDetail]: ':lang?/:titleUrlSlug?/:id',
      [PagesEnum.ReviewNote]: 'ReviewNote',
      [PagesEnum.ReviewNoteList]: 'ReviewNoteList',
      [PagesEnum.SearchListContainer]: 'SearchList',
      [PagesEnum.Privacy]: 'Privacy',
      [PagesEnum.EmailLoginContainer]: 'EmailLoginContainer',
      [PagesEnum.LoginMainContainer]: 'LoginMainContainer',
      [PagesEnum.EmailRegisterContainer]: 'EmailRegisterContainer',
      [PagesEnum.EmailOTPContainer]: 'EmailOTPContainer',
      [PagesEnum.EmailBasicInfoContainer]: 'EmailBasicInfoContainer',
      [PagesEnum.SocialMGMContainer]: 'SocialMGMContainer',
      [PagesEnum.SocialOTPContainer]: 'SocialOTPContainer',
      [PagesEnum.SocialBasicInfoContainer]: 'SocialBasicInfoContainer',
      [PagesEnum.ForgotPasswordContainer]: 'ForgotPasswordContainer',
      [PagesEnum.ProfileDetailContainer]: 'ProfileDetail',
      [PagesEnum.ReleaseNoteContainer]: 'ReleaseNoteContainer',
      [PagesEnum.UserAchievementContainer]: 'UserAchievementContainer/:id',
      [PagesEnum.AllAchievementContainer]: 'AllAchievementContainer',
      [PagesEnum.FollowerContainer]: 'FollowerContainer/:id/:type',
      [PagesEnum.SpiritsCategoriesContainer]: 'SpiritsCategoriesContainer',
      [PagesEnum.MainFilterModal]: 'MainFilterModal',
      [PagesEnum.BrandFilterModal]: 'BrandFilterModal',
      [PagesEnum.PairingFilterModal]: 'PairingFilterModal',
      [PagesEnum.CountryFilterModal]: 'CountryFilterModal',
      [PagesEnum.FlavorFilterModal]: 'FlavorFilterModal',
      [PagesEnum.FilterSearchListContainer]: 'FilterSearchListContainer',
      [PagesEnum.CampaignSite]: 'campaign-site/:url?',
      [PagesEnum.Universal]: 'universal/*',
      [PagesEnum.ImagesContainer]: 'ImagesContainer',
      ...withAuthPagesLinking,
    },
  },
}

const DEFAULT_IDLE_UPDATE_AD_TIME = 30

interface ApplicationNavigatorProps {
  fromServer: boolean
}

const ApplicationNavigator = ({ fromServer }: ApplicationNavigatorProps) => {
  const { Layout, darkMode, NavigationTheme, Images } = useTheme()
  const dispatch = useDispatch()
  const { updateAdvertiseSession } = useUpdateSessions({ renew: true })
  const isIdle = useIdleMins(DEFAULT_IDLE_UPDATE_AD_TIME)

  const globalState = useSelector(getGlobal)

  const { snackbar, bottomDialog, productImageLightBox } = globalState
  const windowSize = useWindowSize()
  const isLogin = useCheckLogin()
  const { isSuccess: getI18nSuccess } = useGetNestedSetI18n()

  const { data: getMeData } = userApi.useGetMeQuery()

  React.useLayoutEffect(() => {
    const language = 'zh'
    dispatch(changeLanguage({ language }))
    dispatch(changeTheme({ darkMode: false }))
  }, [dispatch])

  React.useEffect(() => {
    if (getMeData) {
      const { locale } = getMeData
      dispatch(changeLanguage({ language: locale || 'zh' }))
      i18n.changeLanguage(locale)
    }
  }, [dispatch, getMeData])

  React.useEffect(() => {
    updateAdvertiseSession()
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  React.useEffect(() => {
    if (isIdle) {
      updateAdvertiseSession()
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isIdle])

  const handleContainerPaddingBottom = (): number => {
    if (windowSize.width > 650) {
      return 50
    }
    if (
      windowSize.width < 650 &&
      windowSize.width > SHOW_BOTTOM_WARNING_SIGN_WIDTH
    ) {
      return 30
    }
    return 0
  }

  const handleWarningTextSize = (): number => {
    if (windowSize.width > 905) {
      return 32
    }
    if (windowSize.width < 905 && windowSize.width > 745) {
      return 20
    }
    if (windowSize.width < 745) {
      return 16
    }
    return 0
  }

  const handleLetterSpacing = (): number => {
    if (windowSize.width > 650) {
      return 20
    }
    if (windowSize.width < 835 && windowSize.width > 700) {
      return 15
    }
    if (windowSize.width < 700) {
      return 8
    }
    return 0
  }

  const styles = style(
    windowSize,
    handleContainerPaddingBottom,
    handleLetterSpacing,
    handleWarningTextSize,
  )

  // 因為 icon 與 logo 在 app 層拿不到
  const GeneralDescription = useCallback(() => {
    return (
      <Helmet>
        <meta
          property="og:image"
          content={`${getConfig().DOMAIN}/assets/logo.svg`}
        />
        <link rel="icon" href={Images.favicon} />
        <link rel="apple-touch-icon" href={Images.app_icon} />
      </Helmet>
    )
  }, [Images])

  // 酒的警告標語，只有在大網超過 430 width 才會出現
  const WarningSlogan = useCallback(() => {
    if (windowSize.width > SHOW_BOTTOM_WARNING_SIGN_WIDTH) {
      return (
        <View style={styles.warningContainer}>
          <Text style={styles.warningText}>禁止酒駕</Text>
          <Image
            source={Images.warningIcon}
            resizeMode="contain"
            style={[styles.warningIcon]}
          />
          <Text style={styles.warningText}>酒後不開車</Text>
          <Text style={styles.warningText}>安全有保障</Text>
        </View>
      )
    }
  }, [Images, styles, windowSize.width])

  if (!getI18nSuccess) {
    return <></>
  }

  return (
    <Provider>
      {GeneralDescription()}
      <Image
        source={Images.desktop_bg}
        style={[
          styles.background,
          {
            width: window ? windowSize?.width : Dimensions.get('window').width,
            height: window
              ? windowSize?.height
              : Dimensions.get('window').height,
            opacity: 0.9,
          },
        ]}
        resizeMode="cover"
      />
      <View
        style={[
          Layout.fill,
          { backgroundColor: Colors.background.surface },
          styles.container,
        ]}
      >
        <NavigationContainer
          theme={NavigationTheme}
          ref={navigationRef}
          linking={linking}
        >
          <ErrorBoundary>
            <StatusBar
              hidden={false}
              backgroundColor="#61dafb"
              barStyle={darkMode ? 'light-content' : 'dark-content'}
            />
            <Stack.Navigator
              screenOptions={{
                headerShown: false,
                // 需要加上這個，android 才能滑動 flatList 否則都只能滑動整個網頁
                cardStyle: { flex: 1 },
                // 避免返回上一頁時，畫面會瞬間白畫面一下
                // TODO: 不確定之後轉成 app 會不會影響到動畫呈現，先做判斷
                // @ts-ignore
                animationEnabled: window ? false : true,
              }}
            >
              {!fromServer && (
                <Stack.Screen
                  name="Startup"
                  component={StartupContainer}
                  options={{
                    headerShown: false,
                    title: '烈酒探索家 - 您的全方位烈酒品飲助理',
                  }}
                />
              )}
              {isLogin && withAuthPages()}
              {withAuthRedirectPages()}
              <Stack.Screen
                name={PagesEnum.Main}
                component={MainNavigator}
                options={{
                  animationEnabled: false,
                }}
              />
              <Stack.Screen
                name={PagesEnum.LoginMainContainer}
                component={LoginMainContainer}
                options={{
                  headerShown: false,
                  title: '登入 | 烈酒探索家 - 您的全方位烈酒品飲助理',
                }}
              />
              <Stack.Screen
                name={PagesEnum.EmailLoginContainer}
                component={EmailLoginContainer}
                options={{
                  headerShown: false,
                  title: '登入 | 烈酒探索家 - 您的全方位烈酒品飲助理',
                }}
              />
              <Stack.Screen
                name={PagesEnum.EmailRegisterContainer}
                component={EmailRegisterContainer}
                options={{
                  headerShown: false,
                  title: '註冊 | 烈酒探索家 - 您的全方位烈酒品飲助理',
                }}
              />
              <Stack.Screen
                name={PagesEnum.EmailOTPContainer}
                component={EmailOTPContainer}
                options={{
                  headerShown: false,
                  title: '註冊 | 烈酒探索家 - 您的全方位烈酒品飲助理',
                }}
              />
              <Stack.Screen
                name={PagesEnum.EmailBasicInfoContainer}
                component={EmailBasicInfoContainer}
                options={{
                  headerShown: false,
                  title: '註冊 | 烈酒探索家 - 您的全方位烈酒品飲助理',
                }}
              />
              <Stack.Screen
                name={PagesEnum.SocialMGMContainer}
                component={SocialMGMContainer}
                options={{
                  headerShown: false,
                  title: '註冊 | 烈酒探索家 - 您的全方位烈酒品飲助理',
                }}
              />
              <Stack.Screen
                name={PagesEnum.SocialOTPContainer}
                component={SocialOTPContainer}
                options={{
                  headerShown: false,
                  title: '註冊 | 烈酒探索家 - 您的全方位烈酒品飲助理',
                }}
              />
              <Stack.Screen
                name={PagesEnum.SocialBasicInfoContainer}
                component={SocialBasicInfoContainer}
                options={{
                  headerShown: false,
                  title: '註冊 | 烈酒探索家 - 您的全方位烈酒品飲助理',
                }}
              />
              <Stack.Screen
                name={PagesEnum.ForgotPasswordContainer}
                component={ForgotPasswordContainer}
                options={{
                  headerShown: false,
                  title: '忘記密碼 | 烈酒探索家 - 您的全方位烈酒品飲助理',
                }}
              />
              <Stack.Screen
                name={PagesEnum.ReleaseNoteContainer}
                component={ReleaseNoteContainer}
                options={{
                  headerShown: false,
                  title: '發佈公告 | 烈酒探索家 - 您的全方位烈酒品飲助理',
                }}
              />
              <Stack.Screen
                name={PagesEnum.ProfileDetailContainer}
                component={ProfileDetailContainer}
                options={{
                  headerShown: false,
                  title: '我的 | 烈酒探索家 - 您的全方位烈酒品飲助理',
                }}
              />
              <Stack.Screen
                name={PagesEnum.Privacy}
                component={PrivacyContainer}
                options={{
                  headerShown: false,
                  title: '政策條款 | 烈酒探索家 - 您的全方位烈酒品飲助理',
                }}
              />
              <Stack.Screen
                name={PagesEnum.SearchListContainer}
                component={SearchListContainer}
                options={{
                  headerShown: false,
                  title: '搜尋 | 烈酒探索家 - 您的全方位烈酒品飲助理',
                }}
              />
              <Stack.Screen
                name={PagesEnum.NotFound}
                component={NotFound}
                options={{
                  headerShown: false,
                  title: '404 | 烈酒探索家 - 您的全方位烈酒品飲助理',
                }}
              />
              <Stack.Screen
                name={PagesEnum.CampaignSite}
                component={CampaignSite}
                options={{
                  headerShown: false,
                  title: '贊助 | 烈酒探索家 - 您的全方位烈酒品飲助理',
                }}
              />
              <Stack.Screen
                name={PagesEnum.ProductDetail}
                component={ProductDetailContainer}
                options={{
                  headerShown: false,
                }}
              />
              <Stack.Screen
                name={PagesEnum.ReviewNote}
                component={ReviewNoteContainer}
                options={{
                  headerShown: false,
                  title: '筆記詳情 | 烈酒探索家 - 您的全方位烈酒品飲助理',
                }}
              />
              <Stack.Screen
                name={PagesEnum.ReviewNoteList}
                component={ReviewNoteListContainer}
                options={{
                  headerShown: false,
                  title: '全部品飲筆記 | 烈酒探索家 - 您的全方位烈酒品飲助理',
                }}
              />
              <Stack.Screen
                name={PagesEnum.AllAchievementContainer}
                component={AllAchievementContainer}
                options={{
                  headerShown: false,
                  title: '成就徽章 | 烈酒探索家 - 您的全方位烈酒品飲助理',
                }}
              />
              <Stack.Screen
                name={PagesEnum.ProfileMainContainer}
                component={ProfileMainContainer}
                options={{
                  headerShown: false,
                  title: '個人頁面 | 烈酒探索家 - 您的全方位烈酒品飲助理',
                }}
              />
              <Stack.Screen
                name={PagesEnum.FollowerContainer}
                component={FollowerContainer}
                options={{
                  headerShown: false,
                  title: '追蹤列表 | 烈酒探索家 - 您的全方位烈酒品飲助理',
                }}
              />
              <Stack.Screen
                name={PagesEnum.SpiritsCategoriesContainer}
                component={SpiritsCategoriesContainer}
                options={{
                  headerShown: false,
                  title: '探索各類酒 | 烈酒探索家 - 您的全方位烈酒品飲助理',
                }}
              />
              <Stack.Screen
                name={PagesEnum.UserAchievementContainer}
                component={UserAchievementContainer}
                options={{
                  headerShown: false,
                  title: '成就徽章 | 烈酒探索家 - 您的全方位烈酒品飲助理',
                }}
              />
              <Stack.Screen
                name={PagesEnum.FilterSearchListContainer}
                component={FilterSearchListContainer}
                options={{
                  headerShown: false,
                  title: '探索 | 烈酒探索家 - 您的全方位烈酒品飲助理',
                }}
              />
              {/* modal 頁面 */}
              <Stack.Group screenOptions={{ presentation: 'modal' }}>
                <Stack.Screen
                  name={PagesEnum.MainFilterModal}
                  component={MainFilterModal}
                  options={{
                    headerShown: false,
                    title: '探索 | 烈酒探索家 - 您的全方位烈酒品飲助理',
                  }}
                />
                <Stack.Screen
                  name={PagesEnum.BrandFilterModal}
                  component={BrandFilterModal}
                  options={{
                    headerShown: false,
                    title: '探索 | 烈酒探索家 - 您的全方位烈酒品飲助理',
                  }}
                />
                <Stack.Screen
                  name={PagesEnum.PairingFilterModal}
                  component={PairingFilterModal}
                  options={{
                    headerShown: false,
                    title: '探索 | 烈酒探索家 - 您的全方位烈酒品飲助理',
                  }}
                />
                <Stack.Screen
                  name={PagesEnum.CountryFilterModal}
                  component={CountryFilterModal}
                  options={{
                    headerShown: false,
                    title: '探索 | 烈酒探索家 - 您的全方位烈酒品飲助理',
                  }}
                />
                <Stack.Screen
                  name={PagesEnum.FlavorFilterModal}
                  component={FlavorFilterModal}
                  options={{
                    headerShown: false,
                    title: '探索 | 烈酒探索家 - 您的全方位烈酒品飲助理',
                  }}
                />
                <Stack.Screen
                  name={PagesEnum.Universal}
                  component={Universal}
                  options={{
                    headerShown: false,
                  }}
                />
                <Stack.Screen
                  name={PagesEnum.ImagesContainer}
                  component={ImagesContainer}
                  options={{
                    headerShown: false,
                    title: '相片 | 烈酒探索家 - 您的全方位烈酒品飲助理',
                  }}
                />
              </Stack.Group>
              {/* modal 頁面 */}
            </Stack.Navigator>
          </ErrorBoundary>
        </NavigationContainer>
        <Snackbar
          visible={snackbar.visible}
          position={snackbar.position}
          message={snackbar.message}
          type={snackbar.type}
          duration={snackbar.duration}
          showIcon={snackbar.showIcon}
        />
        {bottomDialog?.visible && (
          <BottomDialog
            title={bottomDialog.title}
            titleStyle={bottomDialog?.titleStyle || {}}
            content={bottomDialog?.content || <View />}
            disabledBackgroundClose={
              bottomDialog?.disabledBackgroundClose || false
            }
            handleClickBackground={bottomDialog?.handleClickBackground}
          />
        )}
        {/* 點擊產品圖片放大 包含搜尋列表（spiritCard）、wishlist(spiritCardWithContent) */}
        <ImageLightBox
          visible={productImageLightBox?.visible}
          resizeMode={productImageLightBox?.resizeMode}
          imagePath={productImageLightBox?.imagePath}
          imageStyle={productImageLightBox?.imageStyle || {}}
          containerStyle={productImageLightBox?.containerStyle || {}}
          hideModal={() => dispatch(globalActions.closeProductImageLightBox())}
        />
      </View>
      {WarningSlogan()}
    </Provider>
  )
}

const style = (
  windowSize: { width: number; height: number },
  handleContainerPaddingBottom: () => number,
  handleLetterSpacing: () => number,
  handleWarningTextSize: () => number,
) =>
  StyleSheet.create({
    background: {
      position: 'absolute',
      top: 0,
      left: 0,
    },
    // FIXME: 網頁開發時讓畫面在中間，之後拿掉
    container: {
      marginTop: 0,
      marginBottom: 0,
      marginLeft: 'auto',
      marginRight: 'auto',
      width: '100%',
      maxWidth: 430,
      paddingBottom: handleContainerPaddingBottom(),
    },
    warningContainer: {
      position: 'absolute',
      bottom: 0,
      width: '100%',
      flexDirection: 'row',
      justifyContent: 'center',
      alignItems: 'center',
      backgroundColor: Colors.background.onSurface,
      letterSpacing: handleLetterSpacing(),
    },
    warningText: {
      color: Colors.white,
      fontSize: handleWarningTextSize(),
      marginRight: windowSize.width > 690 ? 32 : 4,
      whiteSpace: 'nowrap',
    },
    warningIcon: {
      width: windowSize.width > 655 ? 38 : 18,
      height: windowSize.width > 655 ? 38 : 18,
      marginRight: windowSize.width > 690 ? 32 : 8,
    },
  })

export default ApplicationNavigator
