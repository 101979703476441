import ReactGA from 'react-ga4'

export const GA_EVENT_NAME = {
  VIEW_SEARCH_RESULTS: 'view_search_results',
  SIGN_UP: 'sign_up',
  LOGIN: 'login',
  COUPON_COLLECTED: 'coupon_collected',
}

const gtag = ({ eventName, params }: { eventName: string; params: any }) => {
  ReactGA.event(eventName, params)
}

export default gtag
