// 透過 postMessage 傳遞的 type
export enum EventName {
  REDIRECT = 'redirect', // 單純跳轉頁面
  NEW_OLD_CLIENTS = 'new_old_clients', // 新舊會員 2025/03/24~04/07
  I09_SPECIAL_OFFER = 'i09_special_offer', // 加賀導購
  SET_METADATA = 'set_metadata', // 設定 metadata
}

// 發送 iframe 訊息
export const sendIframeMessage = (message: any) => {
  // @ts-ignore
  const iframe = window.document.getElementById('iframe') as HTMLIFrameElement
  // @ts-ignore
  if (iframe && iframe.contentWindow) {
    // @ts-ignore
    iframe.contentWindow.postMessage(message, '*')
  }
}