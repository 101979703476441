/**
 * Used to navigating without the navigation prop
 * @see https://reactnavigation.org/docs/navigating-without-navigation-prop/
 *
 * You can add other navigation functions that you need and export them
 */
import {
  CommonActions,
  createNavigationContainerRef,
} from '@react-navigation/native'

import { PagesEnum } from './Application'
import { MainPagesEnum } from './Main'
import { AuthPagesEnum } from './withAuthPages'

export type RootStackParamList = {
  Startup: undefined
  Main: undefined
  ScanLoadingContainer: undefined
  CommentModal: undefined
  MySpiritContainer: undefined
  ProductDetailContainer: undefined
  [PagesEnum.ReviewNote]: undefined
  [PagesEnum.ProductDetail]: undefined
  [PagesEnum.ProfileMainContainer]: undefined
  [MainPagesEnum.Home]: undefined
  AIChatRoomContainer: undefined
  [PagesEnum.CampaignSite]: { url?: string }
  [PagesEnum.ImagesContainer]: { imagePath: string[] }
  [PagesEnum.SearchListContainer]: undefined
  [PagesEnum.FilterSearchListContainer]: undefined
  [AuthPagesEnum.CouponListContainer]: undefined
  [AuthPagesEnum.CouponDetailContainer]: { id: string }
  [PagesEnum.LoginMainContainer]: undefined
}

export const navigationRef = createNavigationContainerRef<RootStackParamList>()

// 新增用於儲存重定向資訊的類型
type RedirectInfo = {
  screen: keyof RootStackParamList
  params?: any
}

// 新增一個變數來儲存重定向資訊
let pendingRedirect: RedirectInfo | null = null

export const hasPendingRedirect = () => {
  return pendingRedirect !== null
}

// 新增設置重定向的函數
export function setPendingRedirect(
  screen: keyof RootStackParamList,
  params?: any,
) {
  pendingRedirect = { screen, params }
}

// 新增執行重定向的函數
export function handlePendingRedirect() {
  if (pendingRedirect) {
    const { screen, params } = pendingRedirect
    pendingRedirect = null
    navigate(screen, params)
    return true
  }
  return false
}

export function navigateToHome() {
  navigate(MainPagesEnum.Home, undefined)
}

export function navigate(name: keyof RootStackParamList, params: any) {
  if (navigationRef.isReady()) {
    navigationRef.navigate(name, params)
  }
}

export function navigateAndReset(routes = [], index = 0) {
  if (navigationRef.isReady()) {
    navigationRef.dispatch(
      CommonActions.reset({
        index,
        routes,
      }),
    )
  }
}

export function navigateAndSimpleReset(name: string, index = 0) {
  if (navigationRef.isReady()) {
    navigationRef.dispatch(
      CommonActions.reset({
        index,
        routes: [{ name }],
      }),
    )
  }
}

export function getParam() {
  if (navigationRef.isReady()) {
    return navigationRef.getCurrentRoute()?.params
  }
}

export function getRoute() {
  if (navigationRef.isReady()) {
    return navigationRef.getCurrentRoute()
  }
}

export function getRouteHistory() {
  if (navigationRef.isReady()) {
    return navigationRef.getState().routes
  }
}
